/* ------------------------------------------------------------------------------
*
*  # Boxed layout
*
*  Styles for main structure of content area in boxed layout
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */


// Boxed layout base
.layout-boxed {

    // Center main navbar
    .navbar .navbar-boxed,
    .page-header-content,
    .page-header .breadcrumb-boxed,
    .page-container,
    .footer-boxed {
        margin-left: auto;
        margin-right: auto;
    }

    // Boxed navbar
    .navbar:not(.navbar-component) {
        @media (min-width: @grid-float-breakpoint) {
            padding-left: 0;
            padding-right: 0;

            .navbar-boxed {
                position: relative;
                padding-left: @navbar-padding-horizontal;
                padding-right: @navbar-padding-horizontal;

                .navbar-header {
                    margin-left: 0;
                }

                .navbar-brand {
                    padding-left: 0;
                }

                .mega-menu-wide .dropdown-content {
                    left: @navbar-padding-horizontal;
                    right: @navbar-padding-horizontal;
                }
            }
        }
    }

    // Boxed breadcrumbs
    .page-header .breadcrumb-boxed {
        padding-left: @grid-gutter-width;
        padding-right: @grid-gutter-width;
    }

    // Set widths on different screens
    .page-container,
    .navbar .navbar-boxed,
    .page-header .breadcrumb-boxed,
    .page-header-content,
    .footer-boxed {
        @media (min-width: @screen-sm-min) {
            width: @container-sm;
        }
        @media (min-width: @screen-md-min) {
            width: @container-md;
        }
        @media (min-width: @screen-lg-min) {
            width: @container-lg;
        }
    }
}
