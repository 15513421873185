/* ------------------------------------------------------------------------------
*
*  # Main content layout
*
*  Styles for main structure of content area
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */


// Main wrapper
.page-container {
    position: relative;
    padding: @line-height-computed @grid-gutter-width;
    padding-bottom: (@line-height-computed * 3);

    // Change bottom spacing if footer is navbar
    .navbar-bottom &,
    .navbar-bottom-lg &,
    .navbar-bottom-sm &,
    .navbar-bottom-xs & {
        padding-bottom: @line-height-computed;
    }

    // Add top spacing to content area
    .page-header + & {
        padding-top: 0;
    }

    // Desktop view
    @media (min-width: @grid-float-breakpoint) {
        width: 100%;
        display: table;
        table-layout: fixed;

        // Min-height doesn't work in FF, bug. So we set 1px height which is overriden with min-height
        @-moz-document url-prefix() {
            height: 1px;
        }
    }
}

// Main container
.page-content {
    @media (min-width: @grid-float-breakpoint) {
        display: table-row;
    }
}

// Content wrapper
.content-wrapper {
    width: 100%;

    @media (min-width: @grid-float-breakpoint) {
        display: table-cell;
        vertical-align: top;
    }
}
