/* ------------------------------------------------------------------------------
 *
 *  # Components
 *
 *  Common less file with imports of plugins and pages
 *
 *  Version: 1.3
 *  Latest update: Feb 5, 2016
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Global less file
 *
 *  Common less file with imports of plugins and pages
 *
 *  Version: 1.0
 *  Latest update: Feb 5, 2016
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # Utilites
*
*  Additional animations, transitions, transforms and other utilities
*
*  Version: 1.0
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.no-transitions * {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
.btn,
.navbar-link,
.nav-tabs > li > a,
.nav-tabs > li > a:after,
.nav-pills > li > a,
.nav li > a > .label,
.nav li > a > .badge,
.breadcrumb > li > a,
.breadcrumb-elements > li > a,
.tt-suggestion,
.tokenfield .token,
.selectboxit-btn,
.bootstrap-select .btn-default,
.select2-results__option,
.select2-selection__choice__remove,
.dropdown-menu > li > a,
.dropdown-menu > li > label,
.wizard .actions a,
.checker span:after,
.choice span:after,
.selectboxit-option-anchor,
.dt-autofill-list ul li,
.dt-autofill-button .btn,
.dataTable .select-checkbox:before,
.dataTable .select-checkbox:after,
.pika-button,
.sp-input,
.navbar-nav > li > a,
.dropdown-content-footer a,
.icons-list > li > a,
.picker__nav--prev,
.picker__nav--next,
.multiselect.btn-default,
.list-group-item,
.pagination > li > a,
.pagination > li > span,
.pager > li > a,
.pager > li > span,
.datepicker-dropdown .day,
a.label,
a.badge,
.ui-datepicker-buttonpane > button,
.ui-button,
.ui-menu-item,
.ui-selectmenu-button,
.ui-datepicker a,
.media-link,
.menu-list li > a,
.plupload_file_action > a,
.dataTables_paginate .paginate_button,
.dataTables_filter input,
.dt-button,
.picker__list-item,
.picker__day,
.picker__footer,
.sp-replacer,
.sp-cancel,
.sp-choose,
.sp-palette-toggle,
.daterangepicker td,
.daterangepicker th,
.noUi-handle,
.fc-button,
.plupload_button,
.picker__footer button,
.picker__list button,
.AnyTime-btn,
.plupload_filelist li,
.password-indicator-group.input-group-addon,
.password-indicator-label-absolute,
.select2-selection--single:not([class*=bg-]),
.select2-selection--multiple:not([class*=bg-]) .select2-selection__choice,
.bootstrap-select.btn-group .dropdown-menu > li > a .check-mark {
  -webkit-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
  transition: all ease-in-out 0.15s;
}
.close,
.tag [data-role="remove"] {
  -webkit-transition: opacity ease-in-out 0.15s;
  -o-transition: opacity ease-in-out 0.15s;
  transition: opacity ease-in-out 0.15s;
}
.checker span {
  -webkit-transition: border-color ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;
}
.table tr,
.table th,
.table td,
.htContextMenu td,
.ranges ul li {
  -webkit-transition: background-color ease-in-out 0.15s;
  -o-transition: background-color ease-in-out 0.15s;
  transition: background-color ease-in-out 0.15s;
}
/* ------------------------------------------------------------------------------
 *
 *  # Sidebar layouts
 *
 *  Styles for sidebar components, main navigation and sidebar itself
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.sidebar {
  color: #fff;
  position: relative;
  width: 100%;
  display: none;
}
.sidebar .sidebar-content {
  background-color: #263238;
  border: 1px solid transparent;
}
@media (min-width: 769px) {
  .sidebar {
    display: table-cell;
    vertical-align: top;
    width: 280px;
    padding-right: 20px;
  }
}
.sidebar-main {
  z-index: 99;
}
.sidebar-secondary {
  z-index: 98;
}
.sidebar-opposite {
  display: none;
  z-index: 97;
}
@media (min-width: 769px) {
  .sidebar-opposite-visible .sidebar-opposite {
    display: table-cell;
    padding-right: 0;
    padding-left: 20px;
  }
}
.sidebar-default {
  color: #333333;
}
.sidebar-default .sidebar-content {
  background-color: #fff;
  border-color: #ddd;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.sidebar-content {
  position: relative;
  border-radius: 3px;
  margin-bottom: 20px;
}
.sidebar-all-hidden .sidebar-main,
.sidebar-all-hidden .sidebar-secondary,
.sidebar-main-hidden .sidebar-main,
.sidebar-secondary-hidden .sidebar-secondary {
  display: none;
}
@media (max-width: 768px) {
  .sidebar-mobile-main .sidebar-main,
  .sidebar-mobile-secondary .sidebar-secondary,
  .sidebar-mobile-opposite .sidebar-opposite {
    display: block;
  }
}
.category-title {
  position: relative;
  margin: 0;
  padding: 12px 20px;
  padding-right: 46px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.category-title > span {
  display: block;
}
.category-title:not(.h5):not(.h6) > span {
  margin-top: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  line-height: 1.82;
}
.category-title.h5,
.category-title.h6 {
  padding: 20px;
  border-bottom: 0;
}
.category-title > i {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
}
.category-title .icons-list {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
}
.category-title + .sidebar-user {
  padding-top: 5px;
  padding-bottom: 5px;
}
.sidebar-default .category-title {
  border-bottom-color: #ddd;
}
.category-content {
  position: relative;
  padding: 20px;
}
.navigation {
  margin: 0;
  padding: 10px 0;
  list-style: none;
  position: relative;
}
.sidebar-category > .h5 + .category-content > .navigation,
.sidebar-category > .h6 + .category-content > .navigation {
  padding-top: 0;
}
.navigation .hidden-ul {
  display: none;
}
.navigation li {
  position: relative;
}
.navigation li + li {
  margin-top: 1px;
}
.navigation li + .navigation-header {
  margin-top: 10px;
}
.navigation li a {
  color: rgba(255, 255, 255, 0.75);
  display: block;
  -webkit-transition: background 0.15s linear, color 0.15s linear;
  -o-transition: background 0.15s linear, color 0.15s linear;
  transition: background 0.15s linear, color 0.15s linear;
}
.navigation li a:hover,
.navigation li a:focus {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}
.navigation li a > i {
  float: left;
  top: 0;
  margin-top: 2px;
  margin-right: 15px;
  -webkit-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.navigation li a > i.pull-right {
  margin-right: 0;
  margin-left: 15px;
}
.navigation li.disabled > a,
.navigation li.disabled > a:hover,
.navigation li.disabled > a:focus {
  color: inherit;
  background-color: transparent;
  cursor: not-allowed;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.navigation li > .has-ul {
  position: relative;
  padding-right: 36px;
}
.navigation li > .has-ul:after {
  content: "\e9c7";
  font-family: "icomoon";
  font-size: 16px;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 20px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -o-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
}
.navigation li.active > .has-ul:after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.navigation li.navigation-divider {
  margin: 10px 0;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}
.sidebar-default .navigation li.navigation-divider {
  background-color: #eeeeee;
}
.navigation > li > a {
  padding: 12px 20px;
  min-height: 44px;
  font-weight: 500;
}
.navigation > li.active > a,
.navigation > li.active > a:hover,
.navigation > li.active > a:focus {
  background-color: #26A69A;
  color: #fff;
}
.navigation > li.active > a > [class*=text-] {
  color: #fff;
}
.navigation > li.active > a .label,
.navigation > li.active > a .badge {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
  -webkit-transition: background-color ease-in-out 0.2s;
  -o-transition: background-color ease-in-out 0.2s;
  transition: background-color ease-in-out 0.2s;
}
.navigation > li ul {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
}
.navigation > li ul li a {
  padding: 10px 20px;
  padding-left: 51px;
  min-height: 40px;
}
.navigation > li ul .navigation-header {
  padding-left: 51px;
}
.navigation > li > ul > li > ul > li > a,
.navigation > li > ul > li > ul > .navigation-header {
  padding-left: 71px;
}
.navigation > li > ul > li > ul > li > ul > li > a,
.navigation > li > ul > li > ul > li > ul .navigation-header {
  padding-left: 91px;
}
.navigation > li > ul li:first-child {
  padding-top: 10px;
}
.navigation > li > ul li:last-child {
  padding-bottom: 10px;
}
.navigation > li > ul li.active > a,
.navigation > li > ul li.active > a:hover,
.navigation > li > ul li.active > a:focus {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}
.navigation .navigation-header {
  min-height: 30px;
  padding: 10px 20px;
  border-bottom: 0;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1.82;
}
.navigation .navigation-header > span {
  display: block;
  margin-top: 2px;
}
.navigation .navigation-header > i {
  display: none;
}
.navigation .navigation-header > i.pull-right {
  margin-top: 2px;
}
.navigation .navigation-header,
.navigation .navigation-header a {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}
.navigation .navigation-header a:hover,
.navigation .navigation-header a:focus {
  color: #fff;
}
.navigation .navigation-header a i {
  float: none;
  margin: 0;
}
.sidebar-default .navigation .navigation-header,
.sidebar-default .navigation .navigation-header a {
  color: #999999;
}
.sidebar-default .navigation .navigation-header a:hover,
.sidebar-default .navigation .navigation-header a:focus {
  color: #333333;
}
.navigation.nav > .active > .hidden-ul {
  display: block;
}
@media (max-width: 768px) {
  .navigation.nav > li > .hidden-ul {
    display: block;
  }
}
.navigation .label,
.navigation .badge {
  float: right;
}
.sidebar-default .navigation li > a {
  color: #333333;
}
.sidebar-default .navigation li > a:hover,
.sidebar-default .navigation li > a:focus {
  background-color: #f8f8f8;
}
.sidebar-default .navigation li.active > a,
.sidebar-default .navigation li.active > a:hover,
.sidebar-default .navigation li.active > a:focus {
  background-color: #f5f5f5;
  color: #333333;
}
.sidebar-default .navigation li.active > a > [class*=text-] {
  color: #333333;
}
.sidebar-default .navigation li.disabled > a,
.sidebar-default .navigation li.disabled > a:hover,
.sidebar-default .navigation li.disabled > a:focus {
  background-color: transparent;
}
.sidebar-default .navigation li .label-transparent {
  color: #333333;
}
.sidebar-default .navigation > li ul {
  background-color: transparent;
}
.sidebar-default .navigation > li.active > a,
.sidebar-default .navigation > li.active > a:hover,
.sidebar-default .navigation > li.active > a:focus {
  background-color: #f5f5f5;
  color: #333333;
}
.sidebar-default .navigation > li.active > a .label-transparent {
  color: #333333;
}
.navigation-icons-right > li > a,
.navigation-icons-right > li > a.has-ul {
  padding-right: 20px;
}
.navigation-icons-right > li > a > i {
  float: right;
  margin-right: 0;
  margin-left: 15px;
}
.navigation-icons-right > li > a.has-ul:after,
.navigation-icons-right > li.active > .has-ul:after {
  content: none;
}
.navigation-bordered > li {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}
.navigation-bordered > li:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.navigation-bordered > li.navigation-header {
  background-color: rgba(0, 0, 0, 0.1);
}
.navigation-bordered > li + li {
  margin-top: 0;
}
.navigation-bordered > li ul {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.navigation-bordered li + .navigation-header {
  margin-top: 0;
}
.sidebar-default .navigation-bordered > li {
  border-top: 1px solid #eeeeee;
}
.sidebar-default .navigation-bordered > li.navigation-header {
  background-color: #fafafa;
}
.navigation-lg > li > a,
.navigation-lg > li > a > span {
  padding-top: 14px;
  padding-bottom: 14px;
  min-height: 48px;
}
.navigation-lg > li ul li a {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 40px;
}
.navigation-sm > li > a,
.navigation-sm > li > a > span {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 40px;
}
.navigation-sm > li ul li a {
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 32px;
}
.navigation-xs > li > a,
.navigation-xs > li > a > span {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 36px;
}
.navigation-xs > li ul li a {
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 32px;
}
@media (min-width: 769px) {
  .sidebar-fixed.affix {
    position: fixed;
    width: 260px;
    top: 20px;
    bottom: 20px;
    -webkit-transition: bottom ease-in-out 0.15s;
    -o-transition: bottom ease-in-out 0.15s;
    transition: bottom ease-in-out 0.15s;
  }
  .sidebar-fixed.affix .sidebar-content {
    max-height: 100%;
    overflow-y: auto;
  }
  .sidebar-fixed.affix.fixed-sidebar-space {
    bottom: 60px;
  }
  .navbar-bottom .sidebar-fixed.affix.fixed-sidebar-space {
    bottom: 86px;
  }
  .navbar-bottom-lg .sidebar-fixed.affix.fixed-sidebar-space {
    bottom: 90px;
  }
  .navbar-bottom-sm .sidebar-fixed.affix.fixed-sidebar-space {
    bottom: 84px;
  }
  .navbar-bottom-xs .sidebar-fixed.affix.fixed-sidebar-space {
    bottom: 82px;
  }
  .navbar-fixed .sidebar-fixed.affix {
    top: 86px;
  }
  .navbar-fixed-lg .sidebar-fixed.affix {
    top: 90px;
  }
  .navbar-fixed-sm .sidebar-fixed.affix {
    top: 84px;
  }
  .navbar-fixed-xs .sidebar-fixed.affix {
    top: 82px;
  }
  .sidebar-xs .sidebar-fixed.affix {
    width: 56px;
  }
}
.sidebar-fixed-expanded .sidebar-main {
  position: absolute;
  display: block;
  z-index: 1001;
}
.sidebar-xs .sidebar-fixed-expanded .sidebar-main {
  width: 280px;
}
.sidebar-fixed-expanded .content-wrapper {
  padding-left: 76px;
}
@media (min-width: 769px) {
  .sidebar-separate .sidebar-content {
    background-color: transparent;
  }
  .sidebar-separate .sidebar-category {
    background-color: #263238;
    border-radius: 3px;
    margin-bottom: 20px;
  }
  .sidebar-separate.sidebar-default .sidebar-content {
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .sidebar-separate.sidebar-default .sidebar-category {
    background-color: #fff;
    border: 1px solid #ddd;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }
}
@media (min-width: 769px) {
  .sidebar-xs .sidebar-main {
    width: 76px;
  }
  .sidebar-xs .sidebar-main .sidebar-category {
    display: none;
  }
  .sidebar-xs .sidebar-main .sidebar-category-visible {
    display: block;
  }
  .sidebar-xs .sidebar-main .category-title {
    padding: 0;
  }
  .sidebar-xs .sidebar-main .category-title > i {
    padding: 13px 0;
    float: none;
    display: block;
    top: 0;
  }
  .sidebar-xs .sidebar-main .category-title > span {
    display: none;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list {
    position: static;
    text-align: center;
    margin-top: 0;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list > li {
    display: block;
    margin-left: 0;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list > li + li {
    margin-top: 10px;
  }
  .sidebar-xs .sidebar-main .category-title.h6 .icons-list {
    padding-top: 23.538462px;
    padding-bottom: 23.538462px;
  }
  .sidebar-xs .sidebar-main .category-title.h5 .icons-list {
    padding-top: 25.0769236px;
    padding-bottom: 25.0769236px;
  }
  .sidebar-xs .sidebar-main .navigation > li > a {
    display: block;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-xs .sidebar-main .navigation > li > a > span {
    display: none;
    position: absolute;
    top: 0;
    right: -260px;
    background-color: #26A69A;
    border: 1px solid #26A69A;
    padding: 11px 20px;
    width: 260px;
    text-align: left;
    color: #fff;
    cursor: pointer;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
  .sidebar-xs .sidebar-main .navigation > li > a > span .label,
  .sidebar-xs .sidebar-main .navigation > li > a > span .badge {
    background-color: transparent;
    border-color: transparent;
    color: #fff;
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-xs .sidebar-main .navigation > li > a > i {
    margin: 2px 0;
    display: block;
    float: none;
  }
  .sidebar-xs .sidebar-main .navigation > li > .has-ul:after,
  .sidebar-xs .sidebar-main .navigation > li.active > .has-ul:after {
    content: none;
  }
  .sidebar-xs .sidebar-main .navigation > li .has-ul > span {
    border-radius: 0 3px 0 0;
    cursor: default;
  }
  .sidebar-xs .sidebar-main .navigation > li.active > ul {
    display: none!important;
  }
  .sidebar-xs .sidebar-main .navigation > li.disabled:hover > ul,
  .sidebar-xs .sidebar-main .navigation > li.disabled:hover > a > span {
    display: none!important;
  }
  .sidebar-xs .sidebar-main .navigation > li:hover > ul {
    display: block!important;
  }
  .sidebar-xs .sidebar-main .navigation > li:hover > a > span {
    display: block;
  }
  .sidebar-xs .sidebar-main .navigation > li:hover:not(.active) > a {
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul {
    position: absolute;
    right: -260px;
    top: 44px;
    width: 260px;
    display: none;
    background-color: #263238;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0 0 3px 0;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul > li > a {
    padding-left: 20px;
    padding-right: 20px;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul > li > ul > li > a {
    padding-left: 30px;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul > li > ul > li > ul > li > a {
    padding-left: 60px;
  }
  .sidebar-xs .sidebar-main .navigation > .navigation-header {
    padding: 0;
    text-align: center;
  }
  .sidebar-xs .sidebar-main .navigation > .navigation-header > i {
    display: block;
    top: 0;
    padding: 13px 0;
  }
  .sidebar-xs .sidebar-main .navigation > .navigation-header > span {
    display: none;
  }
  .sidebar-xs .sidebar-main .sidebar-user {
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left,
  .sidebar-xs .sidebar-main .sidebar-user .media-right {
    padding: 0;
    text-align: center;
    display: block;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > img,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > img {
    max-width: 100%;
    height: auto!important;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > .img-sm,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > .img-sm {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > .img-xs,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > .img-xs {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-body,
  .sidebar-xs .sidebar-main .sidebar-user .media-right {
    display: none;
  }
  .sidebar-xs .sidebar-main .navigation-lg > li > ul {
    top: 48px;
  }
  .sidebar-xs .sidebar-main .navigation-sm > li > ul {
    top: 40px;
  }
  .sidebar-xs .sidebar-main .navigation-xs > li > ul {
    top: 36px;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > a > span {
    background-color: #f5f5f5;
    border-color: #ddd;
    color: #333333;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > a > span .label,
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > a > span .badge {
    color: #333333;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > ul {
    background-color: #fff;
    border: 1px solid #ddd;
    border-top: 0;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li:hover:not(.active) > a {
    background-color: #f5f5f5;
    color: #333333;
  }
}
.sidebar .row {
  margin-left: -5px;
  margin-right: -5px;
}
.sidebar .row [class*=col-] {
  padding-left: 5px;
  padding-right: 5px;
}
@media (max-width: 768px) {
  .sidebar .sp-container {
    display: block;
    width: 220px;
    margin-left: auto;
    margin-right: auto;
  }
}
.sidebar .sp-flat .sp-picker-container {
  display: block;
  width: 218px;
}
.sidebar .panel-group .panel {
  border-radius: 0;
  border-width: 0 0 1px 0;
}
.sidebar .panel-group .panel:first-child {
  border-top-width: 1px;
}
.sidebar .panel-group .panel + .panel {
  margin-top: 0;
}
.sidebar .media-list-bordered > li {
  border-top: 0;
  border-bottom: 1px solid #eeeeee;
}
.sidebar:not(.sidebar-default) .media .text-muted,
.sidebar:not(.sidebar-default) .media .media-annotation {
  color: rgba(255, 255, 255, 0.8);
}
.sidebar:not(.sidebar-default) .media .media-left > a,
.sidebar:not(.sidebar-default) .media .media-body > a,
.sidebar:not(.sidebar-default) .media .media-right > a {
  color: #fff;
}
.sidebar:not(.sidebar-default) .media .media-link:hover,
.sidebar:not(.sidebar-default) .media .media-link:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
.sidebar:not(.sidebar-default) .media .media-badge {
  border-color: #263238;
}
.sidebar:not(.sidebar-default) .media-list-bordered > li {
  border-color: rgba(255, 255, 255, 0.1);
}
.sidebar .thumbnail {
  margin-bottom: 10px;
}
.sidebar .thumbnail:last-child {
  margin-bottom: 0;
}
.sidebar .thumbnail .zoom-image i {
  font-size: 16px;
  margin-top: -8px;
  margin-left: -8px;
}
.sidebar .sidebar-category .checkbox,
.sidebar .sidebar-category .radio {
  margin-top: 0;
}
.sidebar .sidebar-category .checkbox:last-child,
.sidebar .sidebar-category .radio:last-child {
  margin-bottom: 0;
}
.sidebar .form-group:last-child {
  margin-bottom: 0;
}
.sidebar .nav-tabs {
  border-width: 0 0 1px 0;
}
@media (min-width: 769px) {
  .sidebar .nav-tabs {
    border-width: 1px;
  }
  .sidebar .nav-tabs > li > a {
    border-bottom-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.6);
    border-top: 0;
    border-radius: 0;
  }
  .sidebar .nav-tabs > li > a:hover,
  .sidebar .nav-tabs > li > a:focus {
    color: #fff;
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .sidebar .nav-tabs > .active > a,
  .sidebar .nav-tabs > .active > a:hover,
  .sidebar .nav-tabs > .active > a:focus {
    border-top: 0;
    border-bottom: 0;
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
  .sidebar .nav-tabs > .active:first-child > a {
    border-left-color: transparent!important;
  }
  .sidebar .nav-tabs > .active:last-child > a {
    border-right-color: transparent!important;
  }
  .sidebar .nav-tabs > .open > a {
    color: #fff;
  }
  .sidebar .nav-tabs > .open:not(.active) > a {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
.sidebar .tab-pane .h5:first-child,
.sidebar .tab-pane .h6:first-child {
  padding-top: 0;
}
.sidebar .tab-pane .h5:first-child .icons-list,
.sidebar .tab-pane .h6:first-child .icons-list {
  top: 0;
  margin-top: 4px;
}
@media (min-width: 769px) {
  .sidebar-default .nav-tabs > li > a {
    background-color: #fafafa;
    border-bottom-color: #ddd;
    color: #999999;
  }
  .sidebar-default .nav-tabs > li > a:hover,
  .sidebar-default .nav-tabs > li > a:focus {
    color: #333333;
    border-bottom-color: #ddd;
  }
  .sidebar-default .nav-tabs > li:first-child > a {
    border-top-left-radius: 3px;
  }
  .sidebar-default .nav-tabs > li:last-child > a {
    border-top-right-radius: 3px;
  }
  .sidebar-default .nav-tabs > .active > a,
  .sidebar-default .nav-tabs > .active > a:hover,
  .sidebar-default .nav-tabs > .active > a:focus {
    border-color: #ddd;
    color: #333333;
  }
  .sidebar-default .nav-tabs > .open > a {
    border-bottom-color: #ddd;
    color: #333333;
  }
  .sidebar-default .nav-tabs > .open:not(.active) > a {
    background-color: #fafafa;
  }
}
@media (min-width: 769px) {
  .sidebar-default .nav-justified > li:first-child .dropdown-menu:not(.dropdown-menu-right) {
    left: -1px;
  }
  .sidebar-default .nav-justified > li:last-child .dropdown-menu-right {
    right: -1px;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Main content layout
*
*  Styles for main structure of content area
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.page-container {
  position: relative;
  padding: 20px 20px;
  padding-bottom: 60px;
}
.navbar-bottom .page-container,
.navbar-bottom-lg .page-container,
.navbar-bottom-sm .page-container,
.navbar-bottom-xs .page-container {
  padding-bottom: 20px;
}
.page-header + .page-container {
  padding-top: 0;
}
@media (min-width: 769px) {
  .page-container {
    width: 100%;
    display: table;
    table-layout: fixed;
  }
  @-moz-document url-prefix() {
    .page-container {
      height: 1px;
    }
  }
}
@media (min-width: 769px) {
  .page-content {
    display: table-row;
  }
}
.content-wrapper {
  width: 100%;
}
@media (min-width: 769px) {
  .content-wrapper {
    display: table-cell;
    vertical-align: top;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Boxed layout
*
*  Styles for main structure of content area in boxed layout
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.layout-boxed .navbar .navbar-boxed,
.layout-boxed .page-header-content,
.layout-boxed .page-header .breadcrumb-boxed,
.layout-boxed .page-container,
.layout-boxed .footer-boxed {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 769px) {
  .layout-boxed .navbar:not(.navbar-component) {
    padding-left: 0;
    padding-right: 0;
  }
  .layout-boxed .navbar:not(.navbar-component) .navbar-boxed {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
  }
  .layout-boxed .navbar:not(.navbar-component) .navbar-boxed .navbar-header {
    margin-left: 0;
  }
  .layout-boxed .navbar:not(.navbar-component) .navbar-boxed .navbar-brand {
    padding-left: 0;
  }
  .layout-boxed .navbar:not(.navbar-component) .navbar-boxed .mega-menu-wide .dropdown-content {
    left: 20px;
    right: 20px;
  }
}
.layout-boxed .page-header .breadcrumb-boxed {
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 769px) {
  .layout-boxed .page-container,
  .layout-boxed .navbar .navbar-boxed,
  .layout-boxed .page-header .breadcrumb-boxed,
  .layout-boxed .page-header-content,
  .layout-boxed .footer-boxed {
    width: 769px;
  }
}
@media (min-width: 1025px) {
  .layout-boxed .page-container,
  .layout-boxed .navbar .navbar-boxed,
  .layout-boxed .page-header .breadcrumb-boxed,
  .layout-boxed .page-header-content,
  .layout-boxed .footer-boxed {
    width: 1025px;
  }
}
@media (min-width: 1200px) {
  .layout-boxed .page-container,
  .layout-boxed .navbar .navbar-boxed,
  .layout-boxed .page-header .breadcrumb-boxed,
  .layout-boxed .page-header-content,
  .layout-boxed .footer-boxed {
    width: 1200px;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Footer
*
*  Main footer styles, currently 1 version only
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.footer {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 30px;
}
.footer:not(.navbar-fixed-bottom) {
  z-index: 1000;
}
.footer.navbar {
  left: 0;
  right: 0;
  bottom: 0;
}
body[class*=navbar-bottom] .footer:not(.navbar) {
  display: none;
}
.footer-boxed {
  left: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
}
/* ------------------------------------------------------------------------------
*
*  # Utilites
*
*  Additional animations, transitions, transforms and other utilities
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.rotate-45 {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.rotate-90 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rotate-180 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rotate-45-inverse {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.rotate-90-inverse {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.rotate-180-inverse {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.spinner {
  display: inline-block;
  -webkit-animation: rotation 1s linear infinite;
  -o-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}
.spinner-reverse {
  display: inline-block;
  -webkit-animation: rotation_reverse 1s linear infinite;
  -o-animation: rotation_reverse 1s linear infinite;
  animation: rotation_reverse 1s linear infinite;
}
@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rotation {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-ms-keyframes rotation {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
@-o-keyframes rotation {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotation_reverse {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@-moz-keyframes rotation_reverse {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-ms-keyframes rotation_reverse {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(-360deg);
  }
}
@-o-keyframes rotation_reverse {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(-360deg);
  }
}
@keyframes rotation_reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes bounceOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Uniform form inputs plugin
*
*  Styles for uniform.min.js - form components styling
*
*  Version: 1.2
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */
.checker {
  position: relative;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}
.checker,
.checker span,
.checker input {
  width: 18px;
  height: 18px;
}
.checker span {
  color: #455A64;
  border: 2px solid #607D8B;
  display: inline-block;
  text-align: center;
  position: relative;
  border-radius: 2px;
}
.checker span:after {
  content: "\e600";
  font-family: 'icomoon';
  font-size: 16px;
  line-height: 1;
  position: absolute;
  top: -1px;
  left: -1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0;
  filter: alpha(opacity=0);
}
.checker span.checked:after {
  opacity: 1;
  filter: alpha(opacity=100);
}
.checker input[type=checkbox],
.choice input[type=radio] {
  border: none;
  background: none;
  display: -moz-inline-box;
  display: inline-block;
  margin: 0;
  vertical-align: top;
  cursor: pointer;
  position: absolute;
  top: -2px;
  left: -2px;
  z-index: 2;
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox .checker,
.checkbox-inline .checker {
  position: absolute;
  top: 1px;
  left: 0;
}
.form-horizontal .checkbox .checker,
.form-horizontal .checkbox-inline .checker {
  top: 9px;
}
.checkbox-right .checker {
  left: auto;
  right: 0;
}
.checker.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.checker.disabled,
.checker.disabled input[type=checkbox] {
  cursor: not-allowed;
}
.checkbox > label:active .checker.disabled span:after,
.checkbox-inline:active .checker.disabled span:after {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox > label:active .checker.disabled span.checked:after,
.checkbox-inline:active .checker.disabled span.checked:after {
  opacity: 1;
  filter: alpha(opacity=100);
}
.checker[class*=border-] span {
  border-color: inherit;
  color: inherit;
}
.dropdown-menu > .active:not(.disabled) .checker span,
.dropdown-menu[class*=bg-] .checker span,
.page-header-inverse .form-group > .checkbox .checker span,
.page-header-inverse .form-group > .checkbox-inline .checker span,
.navbar-inverse .navbar-form .form-group > .checkbox .checker span,
.navbar-inverse .navbar-form .form-group > .checkbox-inline .checker span,
.sidebar:not(.sidebar-default) .checkbox .checker span,
.sidebar:not(.sidebar-default) .checkbox-inline .checker span {
  border-color: #fff;
  color: #fff;
}
.choice {
  position: relative;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  border-radius: 100%;
}
.choice,
.choice span,
.choice input {
  width: 18px;
  height: 18px;
}
.choice span {
  border: 2px solid #607D8B;
  display: -moz-inline-box;
  display: inline-block;
  border-radius: 100%;
  text-align: center;
  position: relative;
}
.choice span:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  border: 4px solid;
  border-color: inherit;
  width: 0;
  height: 0;
  border-radius: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
}
.choice span.checked:after {
  opacity: 1;
  filter: alpha(opacity=100);
}
.radio .choice,
.radio-inline .choice {
  position: absolute;
  top: 1px;
  left: 0;
}
.form-horizontal .radio .choice,
.form-horizontal .radio-inline .choice {
  top: 9px;
}
.radio-right .choice {
  left: auto;
  right: 0;
}
.choice.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.choice.disabled,
.choice.disabled input[type=radio] {
  cursor: not-allowed;
}
.radio > label:active .choice.disabled span:after,
.radio-inline:active .choice.disabled span:after {
  opacity: 0;
  filter: alpha(opacity=0);
}
.radio > label:active .choice.disabled span.checked:after,
.radio-inline:active .choice.disabled span.checked:after {
  opacity: 1;
  filter: alpha(opacity=100);
}
.choice[class*=border-] span {
  border-color: inherit;
}
.choice[class*=border-] span:after {
  border-color: inherit;
}
.dropdown-menu > .active .choice span,
.dropdown-menu[class*=bg-] .choice span,
.page-header-inverse .form-group > .radio .choice span,
.page-header-inverse .form-group > .radio-inline .choice span,
.navbar-inverse .navbar-form .form-group > .radio .choice span,
.navbar-inverse .navbar-form .form-group > .radio-inline .choice span,
.sidebar:not(.sidebar-default) .radio .choice span,
.sidebar:not(.sidebar-default) .radio-inline .choice span {
  border-color: #fff;
}
.uploader {
  width: 100%;
  position: relative;
  display: table;
}
.uploader .filename {
  color: #999999;
  height: 36px;
  padding: 7px 12px;
  cursor: pointer;
  width: 100%;
  display: table-cell;
  cursor: default;
  border: 1px solid #ddd;
  border-right: 0;
  background-color: #fff;
  text-align: left;
  word-break: break-word;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.uploader .action {
  display: table-cell;
  cursor: pointer;
  z-index: 1;
  border-radius: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.uploader .action.btn {
  margin-top: 0;
}
.uploader:hover .action,
.uploader:focus .action {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}
.uploader:hover .action.btn-default,
.uploader:focus .action.btn-default {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
}
.uploader:active .action {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
.uploader:active .action.btn-default {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
}
.uploader input[type=file] {
  width: 100%;
  margin-top: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 36px;
  border: 0;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
  filter: alpha(opacity=0);
}
.uploader.disabled .filename {
  background-color: #fafafa;
  cursor: not-allowed;
}
.uploader-lg input[type=file],
.uploader-lg .action,
.uploader-lg .filename {
  height: 40px;
}
.uploader-lg .filename {
  padding: 9px 15px;
  font-size: 14px;
  line-height: 1.4285715;
}
.uploader-sm input[type=file],
.uploader-sm .action,
.uploader-sm .filename {
  height: 34px;
}
.uploader-sm .filename {
  padding: 6px 11px;
  font-size: 12px;
  line-height: 1.6666667;
}
.uploader-xs input[type=file],
.uploader-xs .action,
.uploader-xs .filename {
  height: 32px;
}
.uploader-xs .filename {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.6666667;
}
/* ------------------------------------------------------------------------------
*
*  # Switchery toggles
*
*  Styles for switchery.min.js - toggle switches
*
*  Version: 1.0
*  Latest update: Mar 25, 2015
*
* ---------------------------------------------------------------------------- */
.switchery {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 100px;
  cursor: pointer;
  display: inline-block;
  width: 44px;
  height: 22px;
  vertical-align: middle;
  position: relative;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.checkbox-switchery .switchery {
  position: absolute;
  left: 0;
  margin-top: -2px;
}
.switchery > small {
  background-color: #fff;
  border-radius: 100px;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.checkbox.checkbox-switchery {
  margin-bottom: 14px;
  padding-left: 0;
}
.checkbox.checkbox-switchery.disabled .switchery {
  cursor: not-allowed;
}
.checkbox[class*=switchery-],
.checkbox-inline[class*=switchery-] {
  padding-left: 0;
  padding-right: 0;
}
.switchery-lg.checkbox-switchery {
  margin-bottom: 18px;
}
.switchery-lg.checkbox-switchery .switchery {
  margin-top: -4px;
}
label.switchery-lg {
  margin-bottom: 0;
}
.switchery-lg .switchery {
  height: 26px;
  width: 52px;
}
.switchery-lg .switchery > small {
  height: 26px;
  width: 26px;
}
.switchery-sm.checkbox-switchery {
  margin-bottom: 10px;
}
.switchery-sm.checkbox-switchery .switchery {
  margin-top: 0px;
}
label.switchery-sm {
  margin-bottom: 0;
}
.switchery-sm .switchery {
  height: 18px;
  width: 36px;
}
.switchery-sm .switchery > small {
  height: 18px;
  width: 18px;
}
.switchery-xs.checkbox-switchery {
  margin-bottom: 6px;
}
.switchery-xs.checkbox-switchery .switchery {
  margin-top: 2px;
}
label.switchery-xs {
  margin-bottom: 0;
}
.switchery-xs .switchery {
  height: 14px;
  width: 28px;
}
.switchery-xs .switchery > small {
  height: 14px;
  width: 14px;
}
.checkbox-switchery label,
label.checkbox-switchery {
  position: relative;
  padding-left: 56px;
  margin: 0;
  cursor: pointer;
}
.checkbox-switchery.switchery-lg label,
label.checkbox-switchery.switchery-lg {
  padding-left: 64px;
}
.checkbox-switchery.switchery-sm label,
label.checkbox-switchery.switchery-sm {
  padding-left: 48px;
}
.checkbox-switchery.switchery-xs label,
label.checkbox-switchery.switchery-xs {
  padding-left: 40px;
}
.checkbox-switchery.checkbox-inline {
  margin-bottom: 0;
}
.checkbox-switchery.checkbox-right .switchery {
  left: auto;
  right: 0;
}
.checkbox-switchery.checkbox-right label,
label.checkbox-switchery.checkbox-right {
  padding-left: 0;
  padding-right: 56px;
}
.checkbox-switchery.checkbox-right.switchery-lg label,
label.checkbox-switchery.checkbox-right.switchery-lg {
  padding-left: 0;
  padding-right: 64px;
}
.checkbox-switchery.checkbox-right.switchery-sm label,
label.checkbox-switchery.checkbox-right.switchery-sm {
  padding-left: 0;
  padding-right: 48px;
}
.checkbox-switchery.checkbox-right.switchery-xs label,
label.checkbox-switchery.checkbox-right.switchery-xs {
  padding-left: 0;
  padding-right: 40px;
}
.switchery-double .switchery {
  position: relative;
  margin-left: 7px;
  margin-right: 7px;
  margin-top: -7px;
  margin-bottom: -5px;
}
.switchery-double.checkbox-switchery label,
label.switchery-double.checkbox-switchery {
  padding: 0;
}
.switchery-double.switchery-lg .switchery {
  margin-top: -8px;
  margin-bottom: -6px;
}
.switchery-double.switchery-sm .switchery {
  margin-top: -6px;
  margin-bottom: -4px;
}
.switchery-double.switchery-xs .switchery {
  margin-top: -5px;
  margin-bottom: -3px;
}
/* ------------------------------------------------------------------------------
*
*  # Bootstrap switches
*
*  Styles for switch.min.js - checkbox/radio toggle switches
*
*  Version: 1.1
*  Latest update: Oct 20, 2015
*
* ---------------------------------------------------------------------------- */
.bootstrap-switch {
  display: inline-block;
  cursor: pointer;
  margin-top: -2px;
  margin-right: 7px;
  border-radius: 3px;
  border: 1px solid transparent;
  position: relative;
  text-align: left;
  overflow: hidden;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all ease-in-out 0.05s;
  -o-transition: all ease-in-out 0.05s;
  transition: all ease-in-out 0.05s;
}
.bootstrap-switch .bootstrap-switch-container {
  display: inline-block;
  top: 0;
  border-radius: 3px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.bootstrap-switch.bootstrap-switch-focused {
  outline: 0;
}
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  cursor: pointer;
  display: inline-block !important;
  height: 100%;
  padding: 7px 12px;
}
.bootstrap-switch input[type='radio'],
.bootstrap-switch input[type='checkbox'] {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  filter: alpha(opacity=0);
}
.bootstrap-switch input[type='radio'].form-control,
.bootstrap-switch input[type='checkbox'].form-control {
  height: auto;
}
.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
  cursor: default !important;
}
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
  cursor: default !important;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  -webkit-transition: margin-left 0.2s;
  -o-transition: margin-left 0.2s;
  transition: margin-left 0.2s;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-off .bootstrap-switch-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-on .bootstrap-switch-label {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.bootstrap-switch-handle-on,
.bootstrap-switch-handle-off {
  text-align: center;
  z-index: 1;
}
.bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #333333;
  background-color: #eeeeee;
}
.bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch-handle-off.bootstrap-switch-primary {
  color: #fff;
  background-color: #2196F3;
}
.bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background-color: #F44336;
}
.bootstrap-switch-handle-on.bootstrap-switch-success,
.bootstrap-switch-handle-off.bootstrap-switch-success {
  color: #fff;
  background-color: #4CAF50;
}
.bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch-handle-off.bootstrap-switch-warning {
  color: #fff;
  background-color: #FF5722;
}
.bootstrap-switch-handle-on.bootstrap-switch-info,
.bootstrap-switch-handle-off.bootstrap-switch-info {
  color: #fff;
  background-color: #00BCD4;
}
.bootstrap-switch-handle-on {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.bootstrap-switch-handle-off {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.bootstrap-switch-label {
  text-align: center;
  z-index: 100;
  color: #333333;
  background-color: #fff;
  position: relative;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}
.bootstrap-switch-label:hover {
  background-color: #fcfcfc;
}
.bootstrap-switch-label:active {
  background-color: #fafafa;
}
.bootstrap-switch-label:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 10px;
  margin-top: -5px;
  margin-left: -1px;
  display: inline-block;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch-large .bootstrap-switch-label {
  padding: 9px 15px;
}
.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch-mini .bootstrap-switch-label {
  padding: 5px 10px;
}
.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch-small .bootstrap-switch-label {
  padding: 6px 11px;
}
.checkbox-switch > label,
label.checkbox-switch.checkbox-inline {
  padding: 0;
}
.checkbox-switch.checkbox-right .bootstrap-switch {
  margin-right: 0;
  margin-left: 7px;
}
/* ------------------------------------------------------------------------------
*
*  # Select2 selects
*
*  Styles for select2.js - custom select plugin
*
*  Version: 1.3
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.select2-container {
  outline: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}
.select2-selection--single {
  cursor: pointer;
  outline: 0;
  display: block;
  height: 36px;
  padding: 7px 0;
  line-height: 1.5384616;
  position: relative;
  border: 1px solid transparent;
  white-space: nowrap;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-selection--single:hover,
.select2-selection--single:focus,
.select2-container--open .select2-selection--single {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
}
.select2-selection--single:not([class*=bg-]) {
  background-color: #fff;
  color: #333333;
}
.select2-selection--single:not([class*=bg-]):not([class*=border-]) {
  border-color: #ddd;
}
.select2-selection--single[class*=bg-]:hover,
.select2-selection--single[class*=bg-]:focus,
.select2-container--open .select2-selection--single[class*=bg-] {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.025) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.025) inset;
}
.select2-selection--single[class*=bg-] .select2-selection__placeholder {
  color: #fff;
}
.select2-container--disabled .select2-selection--single[class*=bg-] .select2-selection__placeholder {
  color: rgba(255, 255, 255, 0.75);
}
.select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 12px;
  padding-right: 31px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-selection--single .select2-selection__rendered > i {
  margin-right: 10px;
}
.select2-selection--single .select2-selection__clear {
  position: relative;
  cursor: pointer;
  float: right;
  font-size: 0;
  line-height: 1;
  margin-top: 2px;
  margin-left: 5px;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.select2-selection--single .select2-selection__clear:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.select2-selection--single .select2-selection__clear:after {
  content: '\ed6b';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.select2-selection--single .select2-selection__placeholder {
  color: #999999;
}
.select2-selection--single .select2-selection__arrow:after {
  content: '\e9c5';
  font-family: 'Icomoon';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -8px;
  font-size: 16px;
  line-height: 1;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.select2-container--open .select2-selection--single .select2-selection__arrow:after {
  content: '\e9c6';
}
.select2-selection--single .select2-selection__arrow b {
  display: none;
}
.select2-container--disabled .select2-selection--single {
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.select2-container--disabled .select2-selection--single:not([class*=bg-]) {
  background-color: #fafafa;
  color: #999999;
}
.select2-container--disabled .select2-selection--single[class*=bg-] {
  -webkit-box-shadow: 0 0 0 100px rgba(255, 255, 255, 0.25) inset;
  box-shadow: 0 0 0 100px rgba(255, 255, 255, 0.25) inset;
}
.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-selection--multiple {
  display: block;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: text;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-selection--multiple:not([class*=bg-]) {
  background-color: #fff;
}
.select2-selection--multiple:not([class*=bg-]):not([class*=border-]) {
  border-color: #ddd;
}
.select2-selection--multiple .select2-selection__rendered {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  list-style: none;
  margin: 0;
  padding: 0 2px 2px 2px;
  width: 100%;
}
.select2-selection--multiple .select2-selection__placeholder {
  color: #999999;
}
.select2-container--disabled .select2-selection--multiple:not([class*=bg-]) {
  background-color: #fafafa;
  color: #999999;
}
.select2-container--disabled .select2-selection--multiple[class*=bg-] {
  -webkit-box-shadow: 0 0 0 100px rgba(255, 255, 255, 0.25) inset;
  box-shadow: 0 0 0 100px rgba(255, 255, 255, 0.25) inset;
}
.select2-container--disabled .select2-selection--multiple[class*=bg-] .select2-selection__choice {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.select2-container--disabled .select2-selection--multiple,
.select2-container--disabled .select2-selection--multiple .select2-selection__choice,
.select2-container--disabled .select2-selection--multiple .select2-search__field {
  cursor: not-allowed;
}
.select2-selection--multiple .select2-selection__choice {
  background-color: #455A64;
  color: #fff;
  border-radius: 3px;
  cursor: default;
  float: left;
  margin-right: 2px;
  margin-top: 2px;
  padding: 7px 12px;
}
.select2-selection--multiple .select2-selection__choice > i {
  margin-right: 10px;
}
.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  cursor: pointer;
  float: right;
  font-size: 14px;
  margin-top: 3px;
  line-height: 1;
  margin-left: 7px;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.select2-container--disabled .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  display: none;
}
.select2-selection--multiple .select2-search--inline {
  float: left;
}
.select2-selection--multiple .select2-search--inline .select2-search__field {
  font-size: 100%;
  margin-top: 2px;
  padding: 7px 0;
  background-color: transparent;
  border: 0;
  outline: 0;
  margin-left: 7px;
  -webkit-appearance: textfield;
}
.select2-selection--multiple .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-dropdown {
  background-color: #fff;
  color: #333333;
  border: 1px solid #ddd;
  border-radius: 3px;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 9999;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.select2-results {
  display: block;
}
.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}
.select2-results > .select2-results__options {
  padding-bottom: 7px;
  max-height: 250px;
  overflow-y: auto;
}
.select2-search--hide + .select2-results > .select2-results__options {
  padding-top: 7px;
}
.select2-results:first-child > .select2-results__options {
  padding-top: 7px;
}
.select2-results__option {
  padding: 7px 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-results__option + .select2-results__option {
  margin-top: 1px;
}
.select2-results__option i {
  margin-right: 10px;
}
.select2-results__option i.icon-undefined {
  display: none;
}
.select2-results__option[role=group] {
  padding: 0;
}
.select2-results__option.select2-results__option--highlighted {
  background-color: #f5f5f5;
}
.select2-results__option[aria-disabled=true] {
  color: #999999;
  cursor: not-allowed;
}
.select2-dropdown[class*=bg-] .select2-results__option[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.6);
}
.select2-results__option[aria-selected=true] {
  background-color: #2196F3;
  color: #fff;
}
.select2-results__options--nested > .select2-results__option {
  padding-left: 24px;
  padding-right: 24px;
}
.select2-results__group {
  display: block;
  padding: 7px 12px;
  font-size: 11px;
  line-height: 1.82;
  text-transform: uppercase;
  cursor: default;
  margin-top: 7px;
  margin-bottom: 7px;
}
.select2-results__option:first-child > .select2-results__group {
  margin-top: 0;
}
.select2-results__message {
  color: #999999;
  cursor: default;
}
.select2-dropdown[class*=bg-] .select2-results__message {
  color: rgba(255, 255, 255, 0.75);
}
.select2-results__option.loading-results {
  padding-top: 0;
}
.select2-results__option.loading-results + .select2-results__option {
  margin-top: 7px;
}
.select2-results__option--load-more {
  text-align: center;
  margin-top: 7px;
  cursor: default;
}
.select2-container--open .select2-dropdown {
  left: 0;
}
.select2-container--open .select2-dropdown--above {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--open .select2-dropdown--above[class*=bg-] {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--open .select2-dropdown--below[class*=bg-] {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.select2-search--dropdown {
  display: block;
  position: relative;
  padding: 12px;
}
.select2-search--dropdown:after {
  content: '\e98e';
  font-family: 'icomoon';
  position: absolute;
  top: 50%;
  left: 24px;
  color: inherit;
  display: block;
  font-size: 12px;
  margin-top: -6px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.select2-search--dropdown + .select2-results .select2-results__message:first-child {
  padding-top: 0;
}
.select2-search--dropdown .select2-search__field {
  height: 36px;
  padding: 7px 12px;
  padding-left: 36px;
  border-radius: 3px;
  border: 1px solid #ddd;
  outline: 0;
  width: 100%;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}
.select-lg.select2-selection--single {
  height: 40px;
  padding: 9px 0;
  font-size: 14px;
}
.select-lg.select2-selection--single .select2-selection__rendered {
  padding-left: 15px;
  padding-right: 34px;
}
.select-lg.select2-selection--single .select2-selection__arrow:after {
  right: 15px;
}
.select-lg.select2-selection--multiple .select2-selection__choice {
  padding: 9px 15px;
  font-size: 14px;
}
.select-lg.select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 9px 0;
  font-size: 14px;
}
.select-sm.select2-selection--single {
  height: 34px;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.6666667;
}
.select-sm.select2-selection--single .select2-selection__rendered {
  padding-left: 11px;
  padding-right: 26px;
}
.select-sm.select2-selection--single .select2-selection__arrow:after {
  right: 11px;
}
.select-sm.select2-selection--multiple .select2-selection__choice {
  padding: 6px 11px;
  font-size: 12px;
  line-height: 1.6666667;
}
.select-sm.select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 6px 0;
}
.select-xs.select2-selection--single {
  height: 32px;
  padding: 5px 0;
  font-size: 12px;
  line-height: 1.6666667;
}
.select-xs.select2-selection--single .select2-selection__rendered {
  padding-left: 10px;
  padding-right: 24px;
}
.select-xs.select2-selection--single .select2-selection__arrow:after {
  right: 10px;
}
.select-xs.select2-selection--multiple .select2-selection__choice {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.6666667;
}
.select-xs.select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 5px 0;
  font-size: 12px;
  line-height: 1.6666667;
}
.select2-selection--multiple[class*=bg-] .select2-selection__choice {
  background-color: rgba(0, 0, 0, 0.25);
}
.select2-dropdown[class*=bg-] .select2-search--dropdown .select2-search__field {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: transparent;
  color: #fff;
}
.select2-dropdown[class*=bg-] .select2-results__option[aria-selected=true] {
  background-color: rgba(0, 0, 0, 0.2);
}
.select2-dropdown[class*=bg-] .select2-results__option--highlighted {
  background-color: rgba(0, 0, 0, 0.1);
}
.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  z-index: 99;
  background-color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
}
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: fixed !important;
  width: 1px !important;
}
.select2-result-repository {
  padding-top: 7px;
  padding-bottom: 7px;
}
.select2-result-repository__avatar {
  float: left;
  width: 60px;
  margin-right: 15px;
}
.select2-result-repository__avatar img {
  width: 100%;
  height: auto;
  border-radius: 100px;
}
.select2-result-repository__meta {
  margin-left: 70px;
}
.select2-result-repository__title {
  font-weight: 500;
  word-wrap: break-word;
  margin-bottom: 2px;
}
.select2-result-repository__forks,
.select2-result-repository__stargazers,
.select2-result-repository__watchers {
  display: inline-block;
  font-size: 12px;
}
.select2-result-repository__description {
  font-size: 12px;
}
.select2-result-repository__forks,
.select2-result-repository__stargazers {
  margin-right: 15px;
}
/* ------------------------------------------------------------------------------
 *
 *  # Form Validation
 *
 *  jQuery plugin for simple clientside form validation
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.validation-error-label,
.validation-valid-label {
  margin-top: 7px;
  margin-bottom: 7px;
  display: block;
  color: #F44336;
  position: relative;
  padding-left: 26px;
}
.validation-valid-label {
  color: #4CAF50;
}
.validation-error-label:before,
.validation-valid-label:before {
  font-family: 'icomoon';
  position: absolute;
  top: 2px;
  left: 0;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}
.validation-error-label:empty,
.validation-valid-label:empty {
  display: none;
}
.validation-error-label:before {
  content: '\ed63';
}
.validation-valid-label:before {
  content: '\ee73';
}
/* ------------------------------------------------------------------------------
*
*  # Bootstrap Duallistbox
*
*  Styles for listbox.js - A responsive dual listbox widget optimized for Bootstrap
*
*  Version: 1.2
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */
.bootstrap-duallistbox-container .box1 {
  margin-bottom: 20px;
}
.bootstrap-duallistbox-container select.form-control {
  padding: 7px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.bootstrap-duallistbox-container option {
  display: block;
  padding: 7px 12px;
  border-radius: 2px;
}
.bootstrap-duallistbox-container.moveonselect .move,
.bootstrap-duallistbox-container.moveonselect .remove {
  display: none;
}
.bootstrap-duallistbox-container.moveonselect .moveall,
.bootstrap-duallistbox-container.moveonselect .removeall {
  width: 100%;
  border-top-right-radius: 3px !important;
  border-top-left-radius: 3px !important;
}
.bootstrap-duallistbox-container.moveonselect .btn-group .btn + .btn {
  border-left-width: 1px;
}
.bootstrap-duallistbox-container .buttons {
  width: 100%;
  margin-bottom: -1px;
}
.bootstrap-duallistbox-container .clear1,
.bootstrap-duallistbox-container .clear2 {
  display: none;
  margin-top: 20px;
}
.bootstrap-duallistbox-container .clear1,
.bootstrap-duallistbox-container .clear2,
.bootstrap-duallistbox-container .clear1:hover,
.bootstrap-duallistbox-container .clear2:hover,
.bootstrap-duallistbox-container .clear1:focus,
.bootstrap-duallistbox-container .clear2:focus {
  background-color: transparent;
  border: 0;
  color: #1E88E5;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.bootstrap-duallistbox-container .box1.filtered .clear1,
.bootstrap-duallistbox-container .box2.filtered .clear2 {
  display: inline-block;
}
.bootstrap-duallistbox-container .move,
.bootstrap-duallistbox-container .remove,
.bootstrap-duallistbox-container .moveall,
.bootstrap-duallistbox-container .removeall {
  width: 50%;
}
.bootstrap-duallistbox-container .btn-group .btn {
  margin: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bootstrap-duallistbox-container .btn-group .btn + .btn {
  border-left-width: 0;
}
.bootstrap-duallistbox-container .btn-group .btn > i {
  float: none;
  text-align: center;
}
.bootstrap-duallistbox-container .btn-group .btn > i + i {
  margin-left: -14px;
}
.bootstrap-duallistbox-container .filter {
  margin-bottom: 20px;
  position: relative;
}
.bootstrap-duallistbox-container .filter.placeholder {
  color: #999;
}
.bootstrap-duallistbox-container .info-container .info {
  display: inline-block;
  margin-top: 20px;
}
.bootstrap-duallistbox-container .info-container .info .label {
  margin-right: 7px;
}
.sidebar .bootstrap-duallistbox-container {
  text-align: center;
}
.sidebar .bootstrap-duallistbox-container .box1,
.sidebar .bootstrap-duallistbox-container .box2 {
  float: none;
  width: 100%;
}
.sidebar .bootstrap-duallistbox-container .box1 {
  padding-bottom: 5px;
}
.sidebar .bootstrap-duallistbox-container .box1:after {
  content: '\e9c9';
  font-size: 16px;
  font-family: 'icomoon';
  display: block;
  text-align: center;
  line-height: 1;
  margin: 10px 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
@media (min-width: 1025px) {
  .bootstrap-duallistbox-container .box1 {
    margin-bottom: 0;
  }
  .sidebar .bootstrap-duallistbox-container .box1 {
    margin-bottom: 0;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Stepy wizard
*
*  jQuery plugin which generates a customizable wizard from form fieldsets
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.stepy-step {
  padding: 20px;
  padding-top: 0;
}
.stepy-header {
  list-style: none;
  padding: 0;
  margin: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
}
.panel-flat > .stepy-header {
  border-top: 1px solid #ddd;
}
.stepy-header li {
  cursor: pointer;
  display: table-cell;
  vertical-align: top;
  width: auto;
  padding: 20px 0;
  text-align: center;
  position: relative;
}
.stepy-header li span {
  display: block;
  margin-top: 10px;
  color: #999999;
}
.stepy-header li:before,
.stepy-header li:after {
  content: '';
  display: block;
  position: absolute;
  top: 43px;
  width: 50%;
  height: 2px;
  background-color: #00BCD4;
  z-index: 9;
}
.stepy-header li:before {
  left: 0;
}
.stepy-header li:after {
  right: 0;
}
.stepy-header li:first-child:before,
.stepy-header li:last-child:after {
  content: none;
}
.stepy-header li div {
  background-color: #00BCD4;
  font-size: 0;
  position: relative;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  width: 38px;
  height: 38px;
  border: 2px solid #00BCD4;
  z-index: 10;
  line-height: 34px;
  text-align: center;
  border-radius: 50%;
}
.stepy-header li div:after {
  content: '\ed6c';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 34px;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.stepy-header li.stepy-active:after,
.stepy-header li.stepy-active ~ li:before,
.stepy-header li.stepy-active ~ li:after {
  background-color: #f5f5f5;
}
.stepy-header li.stepy-active ~ li div {
  border-color: #eeeeee;
  background-color: #fff;
  color: #ccc;
  font-size: 14px;
}
.stepy-header li.stepy-active ~ li div:after {
  content: none;
}
.stepy-header li.stepy-active div {
  cursor: auto;
  border-color: #00BCD4;
  background-color: #fff;
  color: #00BCD4;
}
.stepy-header li.stepy-active div:after {
  content: '\e913';
}
.stepy-header li.stepy-active span {
  color: #333333;
}
@media (max-width: 769px) {
  .stepy-header {
    margin-bottom: 20px;
  }
  .stepy-header li {
    display: block;
    float: left;
    width: 50%;
    padding-bottom: 0;
  }
  .stepy-header li:first-child:before,
  .stepy-header li:last-child:after {
    content: '';
  }
  .stepy-header li.stepy-active:last-child:after {
    background-color: #00BCD4;
  }
}
@media (max-width: 480px) {
  .stepy-header li {
    width: 100%;
  }
  .stepy-header li.stepy-active:after {
    background-color: #00BCD4;
  }
}
.stepy-navigator {
  text-align: right;
}
.stepy-navigator .btn + .btn {
  margin-left: 5px;
}
/* ------------------------------------------------------------------------------
*
*  # Summernote editor
*
*  Super simple WYSIWYG Editor for Bootstrap framework
*
*  Version: 1.3
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */
@font-face {
  font-family: "summernote";
  font-style: normal;
  font-weight: normal;
  src: url("../css/icons/summernote//summernote.eot?ad8d7e2d177d2473aecd9b35d16211fb");
  src: url("../css/icons/summernote//summernote.eot?#iefix") format("embedded-opentype"), url("../css/icons/summernote//summernote.woff?ad8d7e2d177d2473aecd9b35d16211fb") format("woff"), url("../css/icons/summernote//summernote.ttf?ad8d7e2d177d2473aecd9b35d16211fb") format("truetype");
}
[class^="note-icon-"]:before,
[class*=" note-icon-"]:before {
  display: inline-block;
  font: normal normal normal 14px summernote;
  font-size: inherit;
  text-decoration: inherit;
  text-rendering: auto;
  text-transform: none;
  vertical-align: middle;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.note-icon-align-center:before {
  content: "\f101";
}
.note-icon-align-indent:before {
  content: "\f102";
}
.note-icon-align-justify:before {
  content: "\f103";
}
.note-icon-align-left:before {
  content: "\f104";
}
.note-icon-align-outdent:before {
  content: "\f105";
}
.note-icon-align-right:before {
  content: "\f106";
}
.note-icon-align:before {
  content: "\f107";
}
.note-icon-arrows-alt:before {
  content: "\f108";
}
.note-icon-bold:before {
  content: "\f109";
}
.note-icon-caret:before {
  content: "\f10a";
}
.note-icon-chain-broken:before {
  content: "\f10b";
}
.note-icon-circle:before {
  content: "\f10c";
}
.note-icon-close:before {
  content: "\f10d";
}
.note-icon-code:before {
  content: "\f10e";
}
.note-icon-eraser:before {
  content: "\f10f";
}
.note-icon-font:before {
  content: "\f110";
}
.note-icon-frame:before {
  content: "\f111";
}
.note-icon-italic:before {
  content: "\f112";
}
.note-icon-link:before {
  content: "\f113";
}
.note-icon-magic:before {
  content: "\f114";
}
.note-icon-menu-check:before {
  content: "\f115";
}
.note-icon-minus:before {
  content: "\f116";
}
.note-icon-orderedlist:before {
  content: "\f117";
}
.note-icon-pencil:before {
  content: "\f118";
}
.note-icon-picture:before {
  content: "\f119";
}
.note-icon-question:before {
  content: "\f11a";
}
.note-icon-redo:before {
  content: "\f11b";
}
.note-icon-special-character:before {
  content: "\f11c";
}
.note-icon-square:before {
  content: "\f11d";
}
.note-icon-strikethrough:before {
  content: "\f11e";
}
.note-icon-subscript:before {
  content: "\f11f";
}
.note-icon-summernote:before {
  content: "\f120";
}
.note-icon-superscript:before {
  content: "\f121";
}
.note-icon-table:before {
  content: "\f122";
}
.note-icon-text-height:before {
  content: "\f123";
}
.note-icon-trash:before {
  content: "\f124";
}
.note-icon-underline:before {
  content: "\f125";
}
.note-icon-undo:before {
  content: "\f126";
}
.note-icon-unorderedlist:before {
  content: "\f127";
}
.note-icon-video:before {
  content: "\f128";
}
.note-editor {
  border: 1px solid #ddd;
  border-radius: 3px;
}
.note-editor .note-dropzone {
  position: absolute;
  z-index: 1;
  display: none;
  color: #2196F3;
  background-color: #fff;
  border: 2px dashed #2196F3;
  pointer-events: none;
  opacity: 0.95;
  filter: alpha(opacity=95);
}
.note-editor .note-dropzone .note-dropzone-message {
  display: table-cell;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
}
.note-editor .note-dropzone.hover {
  color: #2196F3;
  border: 2px dashed #2196F3;
}
.note-editor.dragover .note-dropzone {
  display: table;
}
.note-editor .note-toolbar {
  background-color: #fcfcfc;
  border-bottom: 1px solid #ddd;
  padding: 15px 20px;
  padding-top: 0;
  margin: 0;
  border-radius: 3px;
}
.note-editor.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
}
.note-editor.fullscreen .note-editable {
  background-color: #fff;
}
.note-editor.fullscreen .note-resizebar {
  display: none;
}
.note-editor.codeview .note-editable {
  display: none;
}
.note-editor.codeview .note-codable {
  display: block;
}
.note-editor .note-statusbar {
  background-color: #fcfcfc;
}
.note-editor .note-statusbar .note-resizebar {
  width: 100%;
  height: 8px;
  cursor: s-resize;
  border-top: 1px solid #ddd;
}
.note-editor .note-statusbar .note-resizebar .note-icon-bar {
  width: 20px;
  margin: 1px auto;
  border-top: 1px solid #aaaaaa;
}
.note-editor .note-editable {
  padding: 20px;
  overflow: auto;
  outline: 0;
  min-height: 150px;
}
.note-editor .note-editable[contenteditable="false"] {
  background-color: #eeeeee;
}
.note-editor .note-codable {
  display: none;
  width: 100%;
  padding: 20px;
  margin-bottom: 0;
  font-family: Menlo, Monaco, monospace, sans-serif;
  color: #fff;
  background-color: #333333;
  border: 0;
  border-radius: 0;
  resize: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.note-editor .modal-title {
  font-size: 17px;
}
.note-air-editor {
  outline: 0;
}
.note-popover {
  max-width: none;
}
.note-popover .popover-content {
  padding: 15px;
  padding-top: 0;
  margin: 0;
}
.note-popover .popover-content a {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.note-popover .popover-content > .btn-group:last-child {
  margin-right: 0;
}
.note-popover .arrow {
  left: 20px;
}
.note-popover .popover-content > .note-btn-group,
.note-toolbar > .note-btn-group {
  margin-top: 15px;
  margin-right: 15px;
  margin-left: 0;
}
.note-popover .popover-content > .note-btn-group .note-icon-caret:before,
.note-toolbar > .note-btn-group .note-icon-caret:before {
  width: 9px;
  margin-left: 2px;
  margin-right: 2px;
}
.note-popover .popover-content > .note-btn-group i + .note-icon-caret,
.note-toolbar > .note-btn-group i + .note-icon-caret {
  margin-left: 2px;
  margin-right: -5px;
}
.note-popover .popover-content .note-style h1,
.note-toolbar .note-style h1,
.note-popover .popover-content .note-style h2,
.note-toolbar .note-style h2,
.note-popover .popover-content .note-style h3,
.note-toolbar .note-style h3,
.note-popover .popover-content .note-style h4,
.note-toolbar .note-style h4,
.note-popover .popover-content .note-style h5,
.note-toolbar .note-style h5,
.note-popover .popover-content .note-style h6,
.note-toolbar .note-style h6,
.note-popover .popover-content .note-style blockquote,
.note-toolbar .note-style blockquote {
  margin: 0;
}
.note-popover .popover-content .note-table,
.note-toolbar .note-table {
  min-width: 0;
}
.note-popover .popover-content .note-table.dropdown-menu,
.note-toolbar .note-table.dropdown-menu {
  padding: 10px;
}
.note-popover .popover-content .note-table .note-dimension-picker,
.note-toolbar .note-table .note-dimension-picker {
  font-size: 18px;
  margin-bottom: 10px;
}
.note-popover .popover-content .note-table .note-dimension-picker .note-dimension-picker-mousecatcher,
.note-toolbar .note-table .note-dimension-picker .note-dimension-picker-mousecatcher {
  position: absolute!important;
  z-index: 3;
  width: 10em;
  height: 10em;
  cursor: pointer;
}
.note-popover .popover-content .note-table .note-dimension-picker .note-dimension-picker-unhighlighted,
.note-toolbar .note-table .note-dimension-picker .note-dimension-picker-unhighlighted {
  position: relative!important;
  z-index: 1;
  width: 5em;
  height: 5em;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat;
}
.note-popover .popover-content .note-table .note-dimension-picker .note-dimension-picker-highlighted,
.note-toolbar .note-table .note-dimension-picker .note-dimension-picker-highlighted {
  position: absolute!important;
  z-index: 2;
  width: 1em;
  height: 1em;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat;
}
.note-popover .popover-content .note-table .note-dimension-display,
.note-toolbar .note-table .note-dimension-display {
  text-align: center;
}
.note-popover .popover-content .note-color .dropdown-menu,
.note-toolbar .note-color .dropdown-menu {
  min-width: 330px;
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group,
.note-toolbar .note-color .dropdown-menu .btn-group {
  margin: 7px 12px;
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-palette-title,
.note-toolbar .note-color .dropdown-menu .btn-group .note-palette-title {
  margin-bottom: 10px;
  font-weight: 500;
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset {
  padding: 6px 11px;
  cursor: pointer;
  background-color: #fcfcfc;
  text-align: center;
  margin-bottom: 10px;
}
.note-popover .popover-content .note-para .dropdown-menu,
.note-toolbar .note-para .dropdown-menu {
  min-width: 290px;
  padding: 10px;
  text-align: center;
}
.note-popover .popover-content .note-para .dropdown-menu > div:first-child,
.note-toolbar .note-para .dropdown-menu > div:first-child {
  margin-right: 10px;
}
.note-popover .popover-content .dropdown-menu,
.note-toolbar .dropdown-menu {
  min-width: 90px;
}
.note-popover .popover-content .dropdown-menu.right,
.note-toolbar .dropdown-menu.right {
  right: 0;
  left: auto;
}
.note-popover .popover-content .dropdown-menu.right:before,
.note-toolbar .dropdown-menu.right:before {
  right: 9px;
  left: auto!important;
}
.note-popover .popover-content .dropdown-menu.right:after,
.note-toolbar .dropdown-menu.right:after {
  right: 10px;
  left: auto!important;
}
.note-popover .popover-content .dropdown-menu li a i,
.note-toolbar .dropdown-menu li a i {
  display: none;
}
.note-popover .popover-content .dropdown-menu li a.checked,
.note-toolbar .dropdown-menu li a.checked {
  background-color: #f5f5f5;
}
.note-popover .popover-content .note-color-palette,
.note-toolbar .note-color-palette {
  line-height: 1;
}
.note-popover .popover-content .note-color-palette div .note-color-btn,
.note-toolbar .note-color-palette div .note-color-btn {
  width: 17px;
  height: 17px;
  padding: 0;
  margin: 0;
  border: 1px solid #fff;
}
.note-popover .popover-content .note-color-palette div .note-color-btn:hover,
.note-toolbar .note-color-palette div .note-color-btn:hover {
  border: 1px solid #333333;
}
.note-popover .popover-content > .btn-group {
  margin-top: 15px;
  margin-right: 15px;
}
.note-dialog > div {
  display: none;
}
.note-dialog .note-image-dialog .mote-dropzone {
  min-height: 100px;
  margin-bottom: 10px;
  font-size: 25px;
  color: #eeeeee;
  text-align: center;
  border: 4px dashed #eeeeee;
}
.note-dialog .note-help-dialog {
  color: #ccc;
  background-color: transparent;
  background-color: #333333 !important;
  border: 0;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.note-dialog .note-help-dialog a {
  color: #fff;
}
.note-dialog .note-help-dialog .title {
  padding-bottom: 7px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  border-bottom: 1px solid #fff;
}
.note-dialog .note-help-dialog .modal-content {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.note-dialog .note-help-dialog .modal-close {
  color: #999999;
  cursor: pointer;
}
.note-dialog .note-help-dialog .note-shortcut-layout {
  width: 100%;
}
.note-dialog .note-help-dialog .note-shortcut-layout td {
  vertical-align: top;
}
.note-dialog .note-help-dialog .note-shortcut {
  margin-top: 8px;
}
.note-dialog .note-help-dialog .note-shortcut th {
  color: #eeeeee;
  text-align: left;
}
.note-dialog .note-help-dialog .note-shortcut td:first-child {
  min-width: 110px;
  padding-right: 10px;
  font-family: "Courier New";
  color: #eeeeee;
  text-align: right;
}
.note-group-select-from-files label {
  display: block;
}
.note-handle {
  position: relative;
}
.note-handle .note-control-selection {
  position: absolute;
  display: none;
  border: 1px solid #333333;
  z-index: 10;
}
.note-handle .note-control-selection > div {
  position: absolute;
}
.note-handle .note-control-selection .note-control-selection-bg {
  width: 100%;
  height: 100%;
  background-color: #333333;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.note-handle .note-control-selection .note-control-handle,
.note-handle .note-control-selection .note-control-holder,
.note-handle .note-control-selection .note-control-sizing {
  width: 7px;
  height: 7px;
  border: 1px solid #333333;
}
.note-handle .note-control-selection .note-control-sizing {
  background-color: #fff;
}
.note-handle .note-control-selection .note-control-nw {
  top: -5px;
  left: -5px;
  border-right: 0;
  border-bottom: 0;
}
.note-handle .note-control-selection .note-control-ne {
  top: -5px;
  right: -5px;
  border-bottom: 0;
  border-left: 0;
}
.note-handle .note-control-selection .note-control-sw {
  bottom: -5px;
  left: -5px;
  border-top: 0;
  border-right: 0;
}
.note-handle .note-control-selection .note-control-se {
  right: -5px;
  bottom: -5px;
  cursor: se-resize;
}
.note-handle .note-control-selection .note-control-selection-info {
  right: 0;
  bottom: 0;
  padding: 5px;
  margin: 5px;
  color: #fff;
  background-color: #333333;
  border-radius: 3px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
/* ------------------------------------------------------------------------------
 *
 *  # Daterange picker
 *
 *  Date range picker component for Bootstrap
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.daterangepicker {
  position: absolute;
  left: 0;
  margin-top: 5px;
  width: auto;
  padding: 0;
}
.daterangepicker.dropdown-menu {
  max-width: none;
  background-color: transparent;
  border: 0;
  z-index: 1000;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.daterangepicker.dropup {
  margin-top: -7px;
}
.daterangepicker .ranges,
.daterangepicker .calendar {
  float: left;
}
.daterangepicker.opensleft .calendars {
  float: left;
}
.daterangepicker.opensright .calendars {
  float: right;
}
.daterangepicker.single .calendar {
  float: none;
  margin-left: 0;
  margin-right: 0;
}
.daterangepicker.single .ranges {
  display: none;
}
.daterangepicker.show-calendar .calendar {
  display: block;
}
.daterangepicker .calendar {
  display: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin: 7px;
  padding: 14px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.daterangepicker table {
  width: 100%;
  margin: 0;
}
.daterangepicker table tbody th,
.daterangepicker table tbody td {
  cursor: pointer;
}
.daterangepicker th,
.daterangepicker td {
  white-space: nowrap;
  text-align: center;
}
.daterangepicker th.week,
.daterangepicker td.week {
  font-size: 80%;
  color: #ccc;
}
.daterangepicker th {
  color: #999999;
  font-weight: normal;
  font-size: 12px;
}
.daterangepicker th > i {
  top: 0;
}
.daterangepicker th.prev,
.daterangepicker th.next {
  cursor: pointer;
}
.daterangepicker th.available:hover,
.daterangepicker th.available:focus {
  color: #333333;
}
.daterangepicker td.available:hover,
.daterangepicker td.available:focus {
  background-color: #f5f5f5;
}
.daterangepicker td.off,
.daterangepicker td.disabled {
  color: #ccc;
}
.daterangepicker td.disabled {
  cursor: not-allowed;
}
.daterangepicker td.in-range {
  background-color: #f5f5f5;
}
.daterangepicker td.active,
.daterangepicker td.active:hover,
.daterangepicker td.active:focus {
  background-color: #26A69A;
  color: #fff;
  border-radius: 3px;
}
.daterangepicker .table-condensed tr > th,
.daterangepicker .table-condensed tr > td {
  padding: 10px;
  line-height: 1;
}
.daterangepicker .table-condensed thead tr:last-child th {
  padding-top: 14px;
}
.daterangepicker .table-condensed .month {
  font-size: 15px;
  line-height: 1;
  color: #333333;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 400;
}
.daterangepicker select {
  display: inline-block;
}
.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}
.daterangepicker select.yearselect {
  width: 40%;
}
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  width: 60px;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}
.daterangepicker .daterangepicker_input {
  position: relative;
}
.daterangepicker .daterangepicker_input i {
  position: absolute;
  right: 11px;
  top: auto;
  bottom: 10px;
  color: #999999;
}
.daterangepicker .daterangepicker_input input {
  padding-left: 11px;
  padding-right: 34px;
}
.daterangepicker .calendar-time {
  text-align: center;
  margin: 12px 0;
}
.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}
.ranges {
  background-color: #fff;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 200px;
  margin-top: 7px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.opensright .ranges {
  margin-left: 0;
}
.opensleft .ranges {
  margin-right: 0;
}
.ranges ul {
  list-style: none;
  margin: 0;
  padding: 7px 0;
}
.ranges ul + .daterangepicker-inputs {
  border-top: 1px solid #e5e5e5;
}
.ranges ul li {
  color: #333333;
  padding: 8px 12px;
  cursor: pointer;
  margin-top: 1px;
}
.ranges ul li:first-child {
  margin-top: 0;
}
.ranges ul li:hover,
.ranges ul li:focus {
  background-color: #f5f5f5;
}
.ranges ul li.active {
  color: #fff;
  background-color: #26A69A;
}
.ranges .daterangepicker-inputs {
  padding: 12px;
  padding-top: 19px;
}
.ranges .daterangepicker-inputs .daterangepicker_input + .daterangepicker_input {
  margin-top: 19px;
}
.ranges .daterangepicker-inputs .daterangepicker_input > span {
  display: block;
  font-size: 12px;
  margin-bottom: 7px;
  color: #999999;
}
.ranges .daterangepicker-inputs + .range_inputs {
  border-top: 1px solid #e5e5e5;
}
.ranges .range_inputs {
  padding: 12px;
}
.ranges .range_inputs .btn {
  display: block;
  width: 100%;
}
.ranges .range_inputs .btn + .btn {
  margin-top: 12px;
}
@media (min-width: 769px) {
  .ranges {
    margin: 7px;
  }
}
.daterange-custom {
  cursor: pointer;
}
.daterange-custom:after {
  content: '';
  display: table;
  clear: both;
}
.daterange-custom .label,
.daterange-custom .badge {
  margin: 4px 0 0 7px;
  vertical-align: top;
}
.daterange-custom .label-icon {
  margin-top: 0;
  margin-right: 5px;
}
.daterange-custom-display {
  display: inline-block;
  position: relative;
  padding-left: 21px;
  line-height: 1;
}
.daterange-custom-display:after {
  content: '\e9c9';
  font-family: 'icomoon';
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}
.daterange-custom.is-opened .daterange-custom-display:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.daterange-custom-display > i {
  display: inline-block;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.015em;
}
.daterange-custom-display b {
  display: inline-block;
  margin-left: 4px;
  font-weight: 400;
}
.daterange-custom-display b > i {
  font-size: 11px;
  display: block;
  line-height: 12px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
}
.daterange-custom-display em {
  line-height: 30px;
  vertical-align: top;
  margin: 0 4px;
}
@media (max-width: 769px) {
  .opensleft,
  .opensright {
    left: 0!important;
    right: 0;
  }
  .opensleft .calendars,
  .opensright .calendars {
    float: none;
  }
  .daterangepicker.opensleft .ranges,
  .daterangepicker.opensright .ranges,
  .daterangepicker.opensleft .calendar,
  .daterangepicker.opensright .calendar,
  .daterangepicker.opensleft .calendars,
  .daterangepicker.opensright .calendars {
    float: none;
  }
  .daterangepicker {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .daterangepicker .calendar {
    margin-left: 0;
    margin-right: 0;
  }
  .daterangepicker .ranges {
    width: 100%;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Sweet alerts
 *
 *  Styles for sweet_alert.min.js - A beautiful replacement for JavaScript's "Alert"
 *
 *  Version: 1.1
 *  Latest update: Oct 28, 2015
 *
 * ---------------------------------------------------------------------------- */
/* # Core
-------------------------------------------------- */
body.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
.sweet-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 1050;
}
.sweet-alert {
  background-color: #fff;
  width: 470px;
  padding: 20px;
  border-radius: 3px;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -235px;
  margin-top: -200px;
  overflow: hidden;
  display: none;
  z-index: 1060;
}
.sweet-alert h2 {
  margin-top: 10px;
  font-size: 19px;
  text-align: center;
  display: block;
  position: relative;
}
.sweet-alert p {
  text-align: center;
  position: relative;
}
.sweet-alert fieldset {
  border: none;
  position: relative;
}
.sweet-alert button {
  background-color: #2196F3;
  color: #fff;
  border: 0;
  border-radius: 3px;
  padding: 7px 15px;
  margin: 10px 5px 0 5px;
  box-shadow: none!important;
}
.sweet-alert button:hover {
  background-color: #1E88E5;
}
.sweet-alert button:focus {
  outline: 0;
}
.sweet-alert button:active {
  background-color: #42A5F5;
}
.sweet-alert button.cancel {
  background-color: transparent;
  color: #333333;
}
.sweet-alert button[disabled] {
  cursor: default;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.sweet-alert button.confirm[disabled] {
  color: transparent;
}
.sweet-alert button.confirm[disabled] ~ .la-ball-fall {
  visibility: visible;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 1;
  filter: alpha(opacity=100);
}
.sweet-alert button::-moz-focus-inner {
  border: 0;
}
.sweet-alert[data-has-cancel-button=false] button {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.sweet-alert[data-has-cancel-button=false][data-has-confirm-button=false] {
  padding-bottom: 40px;
}
.sweet-alert .sa-error-container {
  background-color: #f5f5f5;
  overflow: hidden;
  padding: 0 10px;
  max-height: 0;
  border-radius: 3px;
  -webkit-transition: padding 0.15s, max-height 0.15s;
  -o-transition: padding 0.15s, max-height 0.15s;
  transition: padding 0.15s, max-height 0.15s;
}
.sweet-alert .sa-error-container p {
  display: inline-block;
  margin-bottom: 0;
}
.sweet-alert .sa-error-container.show {
  padding: 10px 0;
  max-height: 100px;
  -webkit-transition: padding 0.2s, max-height 0.2s;
  -o-transition: padding 0.2s, max-height 0.2s;
  transition: padding 0.2s, max-height 0.2s;
}
.sweet-alert .sa-error-container .icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  background-color: #FF7043;
  color: white;
  text-align: center;
  margin-right: 7px;
}
.sweet-alert .sa-input-error {
  position: absolute;
  top: 20px;
  right: 12px;
  width: 16px;
  height: 16px;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
  opacity: 0;
  filter: alpha(opacity=0);
}
.sweet-alert .sa-input-error:before,
.sweet-alert .sa-input-error:after {
  content: "";
  width: 16px;
  height: 2px;
  background-color: #EF5350;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 50%;
  margin-left: -8px;
}
.sweet-alert .sa-input-error:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .sa-input-error:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .sa-input-error.show {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  filter: alpha(opacity=100);
}
.sweet-alert input {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ddd;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 13px;
  padding: 7px 12px;
  display: none;
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05) inset;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.sweet-alert input::-moz-placeholder {
  color: #bdbdbd;
  opacity: 1;
}
.sweet-alert input:-ms-input-placeholder {
  color: #bdbdbd;
}
.sweet-alert input::-webkit-input-placeholder {
  color: #bdbdbd;
}
.sweet-alert input:focus {
  outline: 0;
}
.sweet-alert input:focus::-moz-placeholder,
.sweet-alert input:focus:-ms-input-placeholder,
.sweet-alert input:focus::-webkit-input-placeholder {
  -webkit-transition: opacity ease 0.3s 0.03s;
  -o-transition: opacity ease 0.3s 0.03s;
  transition: opacity ease 0.3s 0.03s;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.sweet-alert.show-input input {
  display: block;
}
.sweet-alert .sa-confirm-button-container {
  display: inline-block;
  position: relative;
}
.sweet-alert .la-ball-fall {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -27px;
  margin-top: 0;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
}
.sweet-alert .sa-icon {
  width: 80px;
  height: 80px;
  border: 4px solid #ddd;
  border-radius: 50%;
  margin: 10px auto 20px auto;
  padding: 0;
  position: relative;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.sweet-alert .sa-icon.sa-error {
  border-color: #F44336;
}
.sweet-alert .sa-icon.sa-error .sa-x-mark {
  position: relative;
  display: block;
}
.sweet-alert .sa-icon.sa-error .sa-line {
  position: absolute;
  height: 5px;
  width: 47px;
  background-color: #F44336;
  display: block;
  top: 37px;
  border-radius: 2px;
}
.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
  left: 17px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
  right: 16px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .sa-icon.sa-warning {
  border-color: #FF5722;
}
.sweet-alert .sa-icon.sa-warning .sa-body {
  position: absolute;
  width: 5px;
  height: 47px;
  left: 50%;
  top: 10px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #FF5722;
}
.sweet-alert .sa-icon.sa-warning .sa-dot {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  left: 50%;
  bottom: 10px;
  background-color: #FF5722;
}
.sweet-alert .sa-icon.sa-info {
  border-color: #2196F3;
}
.sweet-alert .sa-icon.sa-info:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 29px;
  left: 50%;
  bottom: 17px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #2196F3;
}
.sweet-alert .sa-icon.sa-info:after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  top: 19px;
  background-color: #2196F3;
}
.sweet-alert .sa-icon.sa-success {
  border-color: #4CAF50;
}
.sweet-alert .sa-icon.sa-success:before,
.sweet-alert .sa-icon.sa-success:after {
  content: '';
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .sa-icon.sa-success:before {
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 60px 60px;
  -moz-transform-origin: 60px 60px;
  -ms-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
}
.sweet-alert .sa-icon.sa-success:after {
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 60px;
  -moz-transform-origin: 0 60px;
  -ms-transform-origin: 0 60px;
  transform-origin: 0 60px;
}
.sweet-alert .sa-icon.sa-success .sa-placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(76, 175, 80, 0.2);
  border-radius: 50%;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.sweet-alert .sa-icon.sa-success .sa-fix {
  width: 5px;
  height: 90px;
  background-color: #fff;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .sa-icon.sa-success .sa-line {
  height: 5px;
  background-color: #4CAF50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
}
.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .sa-icon.sa-custom {
  background-size: contain;
  border-radius: 0;
  border: 0;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (max-width: 480px) {
  .sweet-alert {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    left: 20px;
    right: 20px;
  }
}
/* # Animations
-------------------------------------------------- */
@-webkit-keyframes showSweetAlert {
  0% {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }
  45% {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes showSweetAlert {
  0% {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }
  45% {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.showSweetAlert[data-animation=pop] {
  -webkit-animation: showSweetAlert 0.3s;
  -o-animation: showSweetAlert 0.3s;
  animation: showSweetAlert 0.3s;
}
.showSweetAlert[data-animation=none] {
  -webkit-animation: none;
  -o-animation: none;
  animation: none;
}
@-webkit-keyframes hideSweetAlert {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@keyframes hideSweetAlert {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
}
.hideSweetAlert[data-animation=pop] {
  -webkit-animation: hideSweetAlert 0.2s;
  -o-animation: hideSweetAlert 0.2s;
  animation: hideSweetAlert 0.2s;
}
.hideSweetAlert[data-animation=none] {
  -webkit-animation: none;
  -o-animation: none;
  animation: none;
}
@-webkit-keyframes slideFromTop {
  0% {
    top: 0%;
  }
  100% {
    top: 50%;
  }
}
@keyframes slideFromTop {
  0% {
    top: 0%;
  }
  100% {
    top: 50%;
  }
}
.showSweetAlert[data-animation=slide-from-top] {
  -webkit-animation: slideFromTop 0.3s;
  -o-animation: slideFromTop 0.3s;
  animation: slideFromTop 0.3s;
}
@-webkit-keyframes slideToTop {
  0% {
    top: 50%;
  }
  100% {
    top: 0%;
  }
}
@keyframes slideToTop {
  0% {
    top: 50%;
  }
  100% {
    top: 0%;
  }
}
.hideSweetAlert[data-animation=slide-from-top] {
  -webkit-animation: slideToTop 0.4s;
  -o-animation: slideToTop 0.4s;
  animation: slideToTop 0.4s;
}
@-webkit-keyframes slideFromBottom {
  0% {
    top: 70%;
  }
  100% {
    top: 50%;
  }
}
@keyframes slideFromBottom {
  0% {
    top: 70%;
  }
  100% {
    top: 50%;
  }
}
.showSweetAlert[data-animation=slide-from-bottom] {
  -webkit-animation: slideFromBottom 0.3s;
  -o-animation: slideFromBottom 0.3s;
  animation: slideFromBottom 0.3s;
}
@-webkit-keyframes slideToBottom {
  0% {
    top: 50%;
  }
  100% {
    top: 70%;
  }
}
@keyframes slideToBottom {
  0% {
    top: 50%;
  }
  100% {
    top: 70%;
  }
}
.hideSweetAlert[data-animation=slide-from-bottom] {
  -webkit-animation: slideToBottom 0.3s;
  -o-animation: slideToBottom 0.3s;
  animation: slideToBottom 0.3s;
}
@-webkit-keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
.animateSuccessTip {
  -webkit-animation: animateSuccessTip 0.75s;
  -o-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s;
}
@-webkit-keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
.animateSuccessLong {
  -webkit-animation: animateSuccessLong 0.75s;
  -o-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s;
}
@-webkit-keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
@keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
.sa-icon.sa-success.animate::after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  -o-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in;
}
@-webkit-keyframes animateErrorIcon {
  0% {
    -webkit-transform: rotateX(100deg);
    -ms-transform: rotateX(100deg);
    -o-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@keyframes animateErrorIcon {
  0% {
    -webkit-transform: rotateX(100deg);
    -ms-transform: rotateX(100deg);
    -o-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
.animateErrorIcon {
  -webkit-animation: animateErrorIcon 0.5s;
  -o-animation: animateErrorIcon 0.5s;
  animation: animateErrorIcon 0.5s;
}
@-webkit-keyframes animateXMark {
  0% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  50% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  80% {
    margin-top: -6px;
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    -o-transform: scale(1.15);
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@keyframes animateXMark {
  0% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  50% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  80% {
    margin-top: -6px;
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    -o-transform: scale(1.15);
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
.animateXMark {
  -webkit-animation: animateXMark 0.5s;
  -o-animation: animateXMark 0.5s;
  animation: animateXMark 0.5s;
}
@-webkit-keyframes pulseWarning {
  0% {
    border-color: #F8D486;
  }
  100% {
    border-color: #F8BB86;
  }
}
@keyframes pulseWarning {
  0% {
    border-color: #F8D486;
  }
  100% {
    border-color: #F8BB86;
  }
}
.pulseWarning {
  -webkit-animation: pulseWarning 0.75s infinite alternate;
  -o-animation: pulseWarning 0.75s infinite alternate;
  animation: pulseWarning 0.75s infinite alternate;
}
@-webkit-keyframes pulseWarningIns {
  0% {
    background-color: #F8D486;
  }
  100% {
    background-color: #F8BB86;
  }
}
@keyframes pulseWarningIns {
  0% {
    background-color: #F8D486;
  }
  100% {
    background-color: #F8BB86;
  }
}
.pulseWarningIns {
  -webkit-animation: pulseWarningIns 0.75s infinite alternate;
  -o-animation: pulseWarningIns 0.75s infinite alternate;
  animation: pulseWarningIns 0.75s infinite alternate;
}
@-webkit-keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
  -ms-transform: rotate(45deg);
}
.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
  -ms-transform: rotate(-45deg);
}
.sweet-alert .sa-icon.sa-success {
  border-color: transparent;
}
.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  -ms-transform: rotate(45deg);
}
.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  -ms-transform: rotate(-45deg);
}
.la-ball-fall {
  display: block;
  font-size: 0;
  color: #fff;
  width: 54px;
  height: 12px;
}
.la-ball-fall.la-dark {
  color: #333333;
}
.la-ball-fall > div {
  display: inline-block;
  float: none;
  background-color: #fff;
  border: 0 solid #fff;
  width: 6px;
  height: 6px;
  margin: 2px;
  border-radius: 100%;
  -webkit-animation: ball-fall 1s ease-in-out infinite;
  -o-animation: ball-fall 1s ease-in-out infinite;
  animation: ball-fall 1s ease-in-out infinite;
  opacity: 0;
  filter: alpha(opacity=0);
}
.la-ball-fall > div:nth-child(1) {
  -webkit-animation-delay: -200ms;
  animation-delay: -200ms;
}
.la-ball-fall > div:nth-child(2) {
  -webkit-animation-delay: -100ms;
  animation-delay: -100ms;
}
.la-ball-fall > div:nth-child(3) {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}
.la-ball-fall.la-sm {
  width: 26px;
  height: 8px;
}
.la-ball-fall.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 2px;
}
.la-ball-fall.la-2x {
  width: 108px;
  height: 36px;
}
.la-ball-fall.la-2x > div {
  width: 20px;
  height: 20px;
  margin: 8px;
}
.la-ball-fall.la-3x {
  width: 162px;
  height: 54px;
}
.la-ball-fall.la-3x > div {
  width: 30px;
  height: 30px;
  margin: 12px;
}
@-webkit-keyframes ball-fall {
  0% {
    -webkit-transform: translate(0, -145%);
    -ms-transform: translate(0, -145%);
    -o-transform: translate(0, -145%);
    transform: translate(0, -145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  10% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  20% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  80% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  90% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  100% {
    -webkit-transform: translate(0, 145%);
    -ms-transform: translate(0, 145%);
    -o-transform: translate(0, 145%);
    transform: translate(0, 145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
@-moz-keyframes ball-fall {
  0% {
    -webkit-transform: translate(0, -145%);
    -ms-transform: translate(0, -145%);
    -o-transform: translate(0, -145%);
    transform: translate(0, -145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  10% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  20% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  80% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  90% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  100% {
    -webkit-transform: translate(0, 145%);
    -ms-transform: translate(0, 145%);
    -o-transform: translate(0, 145%);
    transform: translate(0, 145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
@-o-keyframes ball-fall {
  0% {
    -webkit-transform: translate(0, -145%);
    -ms-transform: translate(0, -145%);
    -o-transform: translate(0, -145%);
    transform: translate(0, -145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  10% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  20% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  80% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  90% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  100% {
    -webkit-transform: translate(0, 145%);
    -ms-transform: translate(0, 145%);
    -o-transform: translate(0, 145%);
    transform: translate(0, 145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
@keyframes ball-fall {
  0% {
    -webkit-transform: translate(0, -145%);
    -ms-transform: translate(0, -145%);
    -o-transform: translate(0, -145%);
    transform: translate(0, -145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  10% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  20% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  80% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  90% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  100% {
    -webkit-transform: translate(0, 145%);
    -ms-transform: translate(0, 145%);
    -o-transform: translate(0, 145%);
    transform: translate(0, 145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
/* ------------------------------------------------------------------------------
*
*  # NoUI slider
*
*  Styles for NoUI range slider plugin
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.noUi-target {
  border-radius: 100px;
  position: relative;
  direction: ltr;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.noUi-target,
.noUi-target * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
}
.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 100px;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
.noUi-background {
  background-color: #eeeeee;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.noUi-connect {
  background-color: #607D8B;
}
[disabled] .noUI-background {
  opacity: 0.75;
  filter: alpha(opacity=75);
}
[disabled] .noUi-handle {
  cursor: not-allowed;
}
.noUi-horizontal {
  height: 6px;
}
.noUi-horizontal .noUi-handle {
  top: -6px;
  left: -9px;
}
.noUi-horizontal.noUi-extended {
  padding: 0 15px;
}
.noUi-horizontal.noUi-extended .noUi-origin {
  right: -15px;
}
.noUi-horizontal.has-pips {
  margin-bottom: 35px;
}
.noUi-vertical {
  display: inline-block;
  width: 6px;
  height: 150px;
}
.noUi-vertical + .noUi-vertical {
  margin-left: 20px;
}
.noUi-vertical .noUi-handle {
  top: -9px;
  left: -6px;
}
.noUi-vertical.noUi-extended {
  padding: 15px 0;
}
.noUi-vertical.noUi-extended .noUi-origin {
  bottom: -15px;
}
.noUi-vertical.has-pips {
  margin-right: 35px;
}
.noUi-dragable {
  cursor: w-resize;
}
.noUi-vertical .noUi-dragable {
  cursor: n-resize;
}
.noUi-handle {
  width: 18px;
  height: 18px;
  background-color: #fcfcfc;
  cursor: pointer;
  top: -6px;
  position: relative;
  z-index: 1;
  border-radius: 50%;
  border: 1px solid #bbb;
}
.noUi-stacking .noUi-handle {
  z-index: 10;
}
.noUi-handle:after {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -3px;
  margin-left: -3px;
  background-color: #546E7A;
  border-radius: 50%;
}
.noUi-handle:hover,
.noUi-handle:focus {
  background-color: #f8f8f8;
}
.noUi-handle:hover:after,
.noUi-handle:focus:after {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.noUi-handle:active {
  background-color: #fefefe;
}
.noUi-handle:active:after {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.noui-slider-white .noUi-handle:after {
  content: none;
}
.noui-slider-lg .noUi-handle {
  width: 24px;
  height: 24px;
  top: -8px;
}
.noui-slider-lg .noUi-handle:after {
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: -4px;
}
.noui-slider-lg.noUi-horizontal {
  height: 8px;
}
.noui-slider-lg.noUi-horizontal .noUi-handle {
  left: -12px;
}
.noui-slider-lg.noUi-vertical {
  width: 8px;
}
.noui-slider-lg.noUi-vertical .noUi-handle {
  top: -12px;
  left: -8px;
}
.noui-slider-sm .noUi-handle,
.noui-slider-xs .noUi-handle {
  width: 12px;
  height: 12px;
  top: -4px;
}
.noui-slider-sm .noUi-handle:after,
.noui-slider-xs .noUi-handle:after {
  width: 4px;
  height: 4px;
  margin-top: -2px;
  margin-left: -2px;
}
.noui-slider-sm.noUi-horizontal {
  height: 4px;
}
.noui-slider-sm.noUi-horizontal .noUi-handle {
  top: -4px;
  left: -6px;
}
.noui-slider-sm.noUi-vertical {
  width: 4px;
}
.noui-slider-sm.noUi-vertical .noUi-handle {
  top: -6px;
  left: -4px;
}
.noui-slider-xs.noUi-horizontal {
  height: 2px;
}
.noui-slider-xs.noUi-horizontal .noUi-handle {
  top: -5px;
  left: -6px;
}
.noui-slider-xs.noUi-vertical {
  width: 2px;
}
.noui-slider-xs.noUi-vertical .noUi-handle {
  top: -6px;
  left: -5px;
}
.noui-slider-solid .noUi-handle {
  background-color: #999999;
  border-color: #999999;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.noui-slider-solid .noUi-handle:after {
  background-color: #fff;
  -webkit-transition: opacity ease-in-out 0.2s;
  -o-transition: opacity ease-in-out 0.2s;
  transition: opacity ease-in-out 0.2s;
}
.noui-slider-solid .noUi-handle:hover:after,
.noui-slider-solid .noUi-handle:focus:after {
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.noui-slider-solid.noui-slider-primary .noUi-handle {
  background-color: #2196F3;
  border-color: #2196F3;
}
.noui-slider-solid.noui-slider-danger .noUi-handle {
  background-color: #F44336;
  border-color: #F44336;
}
.noui-slider-solid.noui-slider-success .noUi-handle {
  background-color: #4CAF50;
  border-color: #4CAF50;
}
.noui-slider-solid.noui-slider-warning .noUi-handle {
  background-color: #FF5722;
  border-color: #FF5722;
}
.noui-slider-solid.noui-slider-info .noUi-handle {
  background-color: #00BCD4;
  border-color: #00BCD4;
}
.noui-slider-primary .noUi-connect,
.noui-slider-primary.noUi-connect {
  background-color: #2196F3;
}
.noui-slider-danger .noUi-connect,
.noui-slider-danger.noUi-connect {
  background-color: #F44336;
}
.noui-slider-success .noUi-connect,
.noui-slider-success.noUi-connect {
  background-color: #4CAF50;
}
.noui-slider-warning .noUi-connect,
.noui-slider-warning.noUi-connect {
  background-color: #FF5722;
}
.noui-slider-info .noUi-connect,
.noui-slider-info.noUi-connect {
  background-color: #00BCD4;
}
.noUi-tooltip {
  display: none;
  position: absolute;
  border-radius: 3px;
  background: #333333;
  color: #fff;
  top: -43px;
  padding: 7px 12px;
  left: 50%;
  margin-left: -27px;
  text-align: center;
  font-size: 12px;
  width: 54px;
}
.noUi-tooltip:after {
  content: '';
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #333333;
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
}
.noUi-handle:hover .noUi-tooltip {
  display: block;
}
.noUi-pips {
  position: absolute;
  color: #999999;
}
.noUi-value {
  width: 40px;
  position: absolute;
  text-align: center;
  font-size: 11px;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 11px;
}
.noUi-marker {
  position: absolute;
  background-color: #ccc;
}
.noUi-pips-horizontal {
  padding-top: 10px;
  height: 35px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  margin-left: -20px;
  padding-top: 15px;
}
.noUi-value-horizontal.noUi-value-sub {
  padding-top: 10px;
}
.noUi-marker-horizontal.noUi-marker {
  width: 1px;
  height: 3px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 6px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 10px;
}
.noUi-pips-vertical {
  padding-left: 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  width: 15px;
  margin-left: 15px;
  margin-top: -6px;
}
.noUi-marker-vertical.noUi-marker {
  width: 3px;
  height: 1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 6px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 10px;
}
/* ------------------------------------------------------------------------------
 *
 *  # jQuery UI Interactions
 *
 *  Separate styles for jQuery UI library. Component's interactions
 *
 *  Version: 1.1
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.ui-draggable-handle,
.ui-sortable-handle {
  -ms-touch-action: none;
  touch-action: none;
}
.ui-sortable .ui-state-disabled {
  color: #999999;
  cursor: not-allowed;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.sortable-placeholder {
  position: relative;
}
.sortable-placeholder:before {
  content: '';
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: 1px dashed rgba(0, 0, 0, 0.2);
}
.sidebar .sortable-placeholder:before {
  border-left: 0;
  border-right: 0;
  background-color: rgba(0, 0, 0, 0.05);
}
.sidebar-category.ui-sortable-helper {
  background-color: rgba(0, 0, 0, 0.4);
}
.sidebar-default .sidebar-category.ui-sortable-helper {
  background-color: #fcfcfc;
}
.panel + .sortable-placeholder {
  margin-bottom: 20px;
}
.sortable-placeholder + .panel {
  margin-top: 20px;
}
.panel-group > .sortable-placeholder:before {
  border-radius: 3px;
}
.panel-group .panel + .sortable-placeholder {
  margin-top: 5px;
  margin-bottom: 5px;
}
.panel-group .sortable-placeholder + .panel {
  margin-top: 5px;
}
.table .ui-sortable-helper {
  width: 100%;
  background-color: #fff;
  display: table;
}
.table .sortable-placeholder {
  margin: 0;
}
.table .sortable-placeholder:before {
  content: none;
}
.table.ui-sortable {
  position: relative;
}
.ui-resizable {
  position: relative;
}
.ui-resizable,
.ui-resizable .ui-dialog-content {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.ui-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
  -ms-touch-action: none;
  touch-action: none;
}
.ui-resizable-handle.ui-icon {
  display: inline-block;
  border-style: solid;
  border-width: 0 0 6px 6px;
  border-color: transparent transparent #333333 transparent;
}
.ui-resizable-disabled .ui-resizable-handle,
.ui-resizable-autohide .ui-resizable-handle {
  display: none;
}
.ui-resizable-helper {
  border: 1px dashed #ccc;
}
.ui-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0;
}
.ui-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0;
}
.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%;
}
.ui-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%;
}
.ui-resizable-se {
  cursor: se-resize;
  right: 1px;
  bottom: 1px;
}
.ui-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px;
}
.ui-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px;
}
.ui-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px;
}
.ui-selectable {
  -ms-touch-action: none;
  touch-action: none;
}
.ui-selectable-helper {
  position: absolute;
  z-index: 100;
  border: 1px dashed #333333;
}
/* ------------------------------------------------------------------------------
*
*  # jQuery UI Widgets
*
*  Styles for jQuery UI widgets
*
*  Version: 1.2
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */
.ui-accordion .ui-accordion-header {
  display: block;
  cursor: pointer;
  margin: 0;
  outline: 0;
  position: relative;
  background-color: #fff;
  padding: 15px 20px;
  border: 1px solid #ddd;
  margin-top: 5px;
  border-radius: 3px;
}
.ui-accordion .ui-accordion-header:first-child {
  margin-top: 0;
}
.ui-accordion .ui-accordion-header.ui-accordion-header-active {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -8px;
}
.ui-accordion .ui-accordion-header .ui-accordion-header-icon:before {
  content: '\e9b8';
  font-family: 'Icomoon';
  display: block;
  width: 16px;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ui-accordion .ui-accordion-header-active .ui-accordion-header-icon:before {
  content: '\e9b7';
}
.ui-accordion .ui-accordion-icons {
  padding-right: 46px;
}
.ui-accordion .ui-accordion-content {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: 0;
  overflow: auto;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.accordion-sortable-group + .accordion-sortable-group {
  margin-top: 5px;
}
.ui-autocomplete {
  position: absolute;
  display: none;
  padding: 7px 0;
  z-index: 1000;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.ui-autocomplete .ui-autocomplete-category {
  padding: 8px 12px;
  font-size: 11px;
  line-height: 1.82;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 2px;
  margin-bottom: 2px;
}
.ui-autocomplete .ui-autocomplete-category ~ .ui-menu-item {
  padding-left: 24px;
}
.ui-autocomplete-processing:after {
  content: '\eb51';
  font-family: 'Icomoon';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 12px;
  width: 16px;
  text-align: right;
  margin-top: -8px;
  font-size: 16px;
  line-height: 1;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-animation: rotation 1s linear infinite;
  -o-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}
.input-group .ui-autocomplete-input + .input-group-btn > .btn,
.input-group .ui-autocomplete-input + .input-addon > .btn {
  border-left: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ui-button {
  display: inline-block;
  position: relative;
  touch-action: manipulation;
  margin-bottom: 0;
  padding: 7px 12px;
  border-radius: 3px;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  background-color: #fcfcfc;
  border: 1px solid #ddd;
  color: #333;
}
.ui-button.ui-state-hover,
.ui-button.ui-state-focus {
  color: #333;
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
}
.ui-button.ui-state-active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
}
.ui-button.ui-state-disabled {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ui-button[class*=bg-].ui-state-hover,
.ui-button[class*=bg-].ui-state-focus {
  color: #fff;
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}
.ui-button[class*=bg-].ui-state-active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
.ui-button.ui-button-link {
  background-color: transparent;
  border-color: transparent;
}
.ui-button.ui-button-link.ui-state-hover,
.ui-button.ui-button-link.ui-state-focus {
  color: #166dba;
}
.ui-button.ui-button-link.ui-state-hover,
.ui-button.ui-button-link.ui-state-focus,
.ui-button.ui-button-link.ui-state-active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ui-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.ui-button-text-icon-primary .ui-button-icon-primary,
.ui-button-text-icons .ui-button-icon-primary,
.ui-button-icon-primary .ui-button-icons-only {
  margin-right: 7px;
}
.ui-button-text-icon-secondary .ui-button-icon-secondary,
.ui-button-text-icons .ui-button-icon-secondary,
.ui-button-icons-only .ui-button-icon-secondary {
  margin-left: 7px;
}
.ui-button-icon-only {
  padding-left: 9px;
  padding-right: 9px;
}
.ui-button-icon-only .ui-button-text,
.ui-button-icons-only .ui-button-text {
  display: none;
}
.ui-buttonset {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.ui-buttonset .ui-button {
  border-radius: 0;
  margin-left: -1px;
  float: left;
}
.ui-buttonset .ui-button:first-child,
.ui-buttonset .ui-helper-hidden-accessible:first-child + .ui-button {
  margin-left: 0;
}
.ui-buttonset .ui-button:first-child,
.ui-buttonset .ui-helper-hidden-accessible:first-child + .ui-button:not(:last-child) {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.ui-buttonset .ui-button:last-child {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.ui-buttonset-split {
  position: relative;
  display: inline-block;
}
.ui-buttonset-split > ul {
  position: absolute;
  text-align: left;
  min-width: 180px;
}
.ui-datepicker {
  min-width: 270px;
  background-color: #fff;
  padding: 14px;
  display: none;
  border: 1px solid #ddd;
  border-radius: 3px;
  z-index: 190;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.sidebar .ui-datepicker {
  min-width: 0;
}
.ui-datepicker .ui-datepicker-header {
  position: relative;
}
.ui-datepicker .ui-datepicker-title {
  margin: 0 40px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 15px;
  text-align: center;
  line-height: 1;
}
.ui-datepicker .ui-datepicker-title .ui-datepicker-month {
  font-weight: 500;
}
.ui-datepicker .ui-datepicker-title .ui-datepicker-year {
  font-size: 12px;
  color: #999999;
  margin-left: 5px;
}
.ui-datepicker .ui-datepicker-title select {
  outline: 0;
  height: 30px;
  border-color: #ddd;
}
.ui-datepicker .ui-datepicker-title select.ui-datepicker-month,
.ui-datepicker .ui-datepicker-title select.ui-datepicker-year {
  width: 48%;
  font-size: 13px;
  font-weight: 400;
  color: #333333;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  line-height: 1;
  color: #333333;
  padding: 7px;
  border-radius: 2px;
}
.ui-datepicker .ui-datepicker-prev:after,
.ui-datepicker .ui-datepicker-next:after {
  font-family: 'icomoon';
  display: block;
  font-size: 16px;
  width: 16px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: none;
}
.ui-datepicker .ui-datepicker-prev {
  left: 0;
}
.ui-datepicker .ui-datepicker-prev:after {
  content: '\e9c8';
}
.ui-datepicker .ui-datepicker-next {
  right: 0;
}
.ui-datepicker .ui-datepicker-next:after {
  content: '\e9cb';
}
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
  background-color: #f5f5f5;
}
.ui-datepicker table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
}
.ui-datepicker table th {
  text-align: center;
  font-weight: 400;
  border: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  color: #999999;
}
.ui-datepicker table td {
  border: 0;
  padding: 1px;
  position: relative;
}
.ui-datepicker table td span,
.ui-datepicker table td a {
  display: block;
  padding: 6px;
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
  color: #333333;
  min-width: 34px;
}
.ui-datepicker table td .ui-state-hover {
  background-color: #f5f5f5;
}
.ui-datepicker table td.ui-state-disabled span {
  color: #ccc;
}
.ui-datepicker table td.ui-datepicker-week-col {
  padding: 6px;
  color: #999999;
}
.ui-datepicker table td.ui-datepicker-current-day .ui-state-active {
  background-color: #26A69A;
  color: #fff;
}
.ui-datepicker table td.ui-datepicker-today .ui-state-highlight {
  background-color: #2196F3;
  color: #fff;
}
.ui-datepicker table td.ui-datepicker-today .ui-state-highlight:after {
  content: "";
  position: absolute;
  top: 3px;
  right: 3px;
  width: 0;
  height: 0;
  border-top: 6px solid #fff;
  border-left: 6px solid transparent;
}
.ui-datepicker table td a.ui-priority-secondary {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  border: 1px solid #ddd;
  background-color: #fcfcfc;
  font-weight: 400;
  margin-top: 14px;
  cursor: pointer;
  padding: 7px 12px;
  width: auto;
  overflow: visible;
  outline: 0;
  border-radius: 3px;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-state-hover {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}
.ui-datepicker .ui-datepicker-buttonpane button:active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
}
.ui-datepicker.ui-datepicker-multi {
  width: auto!important;
}
.datepicker-inline {
  width: 264px;
  max-width: 100%;
  overflow-x: auto;
}
.full-width.datepicker-inline,
.sidebar .datepicker-inline,
.popover .datepicker-inline {
  width: 100%;
}
.ui-datepicker-trigger {
  position: absolute;
  top: 4px;
  right: 5px;
  z-index: 4;
  padding: 6px;
  cursor: pointer;
}
.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}
.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}
.ui-datepicker-multi .ui-datepicker-group + .ui-datepicker-group {
  padding-left: 15px;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header {
  border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 46%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}
.ui-dialog {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid transparent;
  outline: 0;
  overflow: hidden;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
  .ui-dialog {
    width: 90%!important;
  }
}
.ui-dialog-titlebar {
  position: relative;
  padding: 20px;
  padding-bottom: 0;
}
.ui-draggable .ui-dialog-titlebar {
  cursor: move;
}
.ui-dialog-title {
  float: left;
  font-size: 15px;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ui-dialog-titlebar-close {
  position: absolute;
  background-color: transparent;
  border: 0;
  right: 20px;
  top: 50%;
  padding: 0;
  margin-top: 2px;
}
.ui-dialog-titlebar-close:after {
  content: '\ed6b';
  font-family: 'Icomoon';
  display: block;
  width: 16px;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ui-dialog-titlebar-close.ui-state-hover,
.ui-dialog-titlebar-close.ui-state-focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ui-dialog-content {
  position: relative;
  border: 0;
  padding: 20px;
  background: none;
  overflow: auto;
}
.ui-dialog-content .form-group:last-child,
.ui-dialog-content p:last-child {
  margin-bottom: 0;
}
.ui-dialog-buttonpane {
  padding: 20px;
  padding-top: 0;
}
.ui-dialog-buttonpane .ui-dialog-buttonset {
  float: right;
}
.ui-dialog-buttonpane button + button {
  margin-left: 5px;
  cursor: pointer;
}
.ui-menu {
  list-style: none;
  padding: 7px 0;
  margin: 0;
  display: block;
  outline: none;
  min-width: 180px;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  z-index: 1000;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.ui-menu .ui-menu {
  position: absolute;
  top: -8px !important;
}
.ui-menu .ui-menu-item {
  position: relative;
  margin: 0;
  cursor: pointer;
  padding: 8px 15px;
  min-height: 0;
  outline: 0;
}
.ui-menu .ui-menu-item > a {
  display: block;
  color: #333333;
}
.ui-menu .ui-menu-item > i {
  margin-right: 7px;
}
.ui-menu .ui-menu-item > .ui-menu-icon {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 15px;
}
.ui-menu .ui-menu-item > .ui-menu-icon:after {
  content: '\e9c7';
  font-family: 'icomoon';
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.ui-menu .ui-menu-item.ui-state-disabled,
.ui-menu .ui-menu-item.ui-state-disabled a {
  color: #999999;
  cursor: not-allowed;
}
.ui-menu .ui-menu-header {
  padding: 8px 15px;
  font-size: 11px;
  line-height: 1.82;
  text-transform: uppercase;
  color: #999999;
  margin-top: 7px;
}
.ui-menu .ui-menu-header.highlight {
  margin-top: 0;
  background-color: #f8f8f8;
  color: #999999;
}
.ui-menu .ui-menu-header.highlight:first-child {
  margin-top: 0;
}
.ui-menu .ui-menu-item + .highlight,
.ui-menu .highlight + .ui-menu-item {
  margin-top: 7px;
}
.ui-menu .ui-menu-item > a > i[class*=icon-],
.ui-menu .ui-menu-header > i[class*=icon-] {
  margin-top: 2px;
  float: left;
  margin-right: 10px;
  top: 0;
}
.ui-menu .ui-menu-item > a > i[class*=icon-].pull-right,
.ui-menu .ui-menu-header > i[class*=icon-].pull-right {
  margin-right: 0;
  margin-left: 10px;
}
.ui-menu .ui-menu-divider {
  margin: 7px 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-top: 1px solid #e5e5e5;
}
.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
  color: #333333;
  background-color: #f5f5f5;
}
.ui-menu.ui-state-disabled,
.ui-menu.ui-state-disabled .ui-menu-item,
.ui-menu.ui-state-disabled a {
  color: #999999;
  cursor: not-allowed;
}
.ui-progressbar {
  height: 18px;
  overflow: hidden;
  position: relative;
  background-color: #eeeeee;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.ui-progressbar-value {
  float: left;
  width: 0%;
  height: 100%;
  background-color: #2196F3;
  color: #fff;
  overflow: hidden;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.ui-progressbar-value,
.ui-progressbar-overlay {
  height: 100%;
  background-size: 40px 40px;
}
.ui-progressbar-striped .ui-progressbar-value,
.ui-progressbar-striped .ui-progressbar-overlay {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.ui-progressbar-overlay {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.ui-progressbar-active .ui-progressbar-value,
.ui-progressbar-overlay {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}
.ui-progressbar-indeterminate .ui-progressbar-value {
  background-image: none;
}
.ui-selectmenu-menu {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
.ui-selectmenu-menu .ui-menu {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup {
  font-size: 11px;
  font-weight: 700;
  line-height: 1.82;
  padding: 7px 12px;
  margin: 2px 0;
  text-transform: uppercase;
  height: auto;
  border: 0;
}
.ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup ~ .ui-menu-item {
  padding-left: 24px;
}
.ui-selectmenu-open {
  display: block;
}
.ui-selectmenu-button {
  display: inline-block;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  height: 36px;
  padding: 7px 12px;
  padding-right: 34px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline: 0;
}
.ui-selectmenu-button.ui-state-hover,
.ui-selectmenu-button.ui-state-focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
}
.ui-selectmenu-button:after {
  content: '\e9c5';
  font-family: 'Icomoon';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 12px;
  text-align: right;
  margin-top: -8px;
  font-size: 16px;
  line-height: 1;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ui-selectmenu-button .ui-selectmenu-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ui-selectmenu-disabled {
  cursor: not-allowed;
  background-color: #fafafa;
  color: #999999;
}
.ui-selectmenu-disabled.ui-state-hover,
.ui-selectmenu-disabled.ui-state-focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ui-selectmenu-lg .ui-selectmenu-button {
  height: 40px;
}
.ui-selectmenu-lg .ui-selectmenu-button .ui-selectmenu-text {
  padding: 9px 15px;
  padding-right: 40px;
}
.ui-selectmenu-sm .ui-selectmenu-button {
  height: 34px;
}
.ui-selectmenu-sm .ui-selectmenu-button .ui-selectmenu-text {
  padding: 6px 12px;
  padding-right: 32px;
  font-size: 12px;
  line-height: 1.6666667;
}
.ui-selectmenu-xs .ui-selectmenu-button {
  height: 32px;
}
.ui-selectmenu-xs .ui-selectmenu-button .ui-selectmenu-text {
  padding: 5px 10px;
  padding-right: 30px;
  font-size: 12px;
  line-height: 1.6666667;
}
.ui-selectmenu-images .ui-menu-item > span {
  display: inline-block;
  margin-right: 10px;
  border-radius: 2px;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  float: left;
}
.ui-selectmenu-images .demo-img-amazon {
  background: url('../images/brands/amazon.png') no-repeat;
}
.ui-selectmenu-images .demo-img-youtube {
  background: url('../images/brands/youtube.png') no-repeat;
}
.ui-selectmenu-images .demo-img-twitter {
  background: url('../images/brands/twitter.png') no-repeat;
}
.ui-selectmenu-images .demo-img-bing {
  background: url('../images/brands/bing.png') no-repeat;
}
.ui-selectmenu-images .demo-img-spotify {
  background: url('../images/brands/spotify.png') no-repeat;
}
.ui-slider {
  position: relative;
  text-align: left;
  background-color: #eeeeee;
  border-radius: 100px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 18px;
  height: 18px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #fcfcfc;
  border: 1px solid #bbb;
  outline: 0;
}
.ui-slider .ui-slider-handle.ui-state-hover,
.ui-slider .ui-slider-handle.ui-state-focus {
  background-color: #f8f8f8;
}
.ui-slider .ui-slider-handle.ui-state-hover:after,
.ui-slider .ui-slider-handle.ui-state-focus:after {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.ui-slider .ui-slider-handle.ui-state-active {
  background-color: #fefefe;
}
.ui-slider .ui-slider-handle.ui-state-active:after {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.ui-slider .ui-slider-handle:after {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -3px;
  margin-left: -3px;
  background-color: #546E7A;
  border-radius: 50%;
}
.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  display: block;
  border: 0;
  background-color: #607D8B;
  border-radius: 100px;
}
.ui-slider.ui-slider-disabled {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.ui-slider-horizontal {
  height: 6px;
}
.ui-slider-horizontal .ui-slider-handle {
  top: -6px;
  margin-left: -9px;
}
.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}
.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}
.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}
.ui-slider-vertical {
  width: 6px;
  height: 150px;
  display: inline-block;
  margin: 0 10px;
}
.ui-slider-vertical .ui-slider-handle {
  left: -6px;
  margin-bottom: -9px;
}
.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}
.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}
.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}
.ui-slider-solid .ui-slider-handle {
  background-color: #999999;
  border-color: #999999;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ui-slider-solid .ui-slider-handle:after {
  background-color: #fff;
  -webkit-transition: opacity ease-in-out 0.2s;
  -o-transition: opacity ease-in-out 0.2s;
  transition: opacity ease-in-out 0.2s;
}
.ui-slider-solid .ui-slider-handle.ui-state-hover:after,
.ui-slider-solid .ui-slider-handle.ui-state-active:after {
  background-color: #fff;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.ui-handle-white .ui-slider-handle:after {
  content: none;
}
.ui-slider-lg .ui-slider-handle {
  width: 24px;
  height: 24px;
}
.ui-slider-lg .ui-slider-handle:after {
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: -4px;
}
.ui-slider-lg.ui-slider-horizontal {
  height: 8px;
}
.ui-slider-lg.ui-slider-horizontal .ui-slider-handle {
  top: -8px;
  margin-left: -12px;
}
.ui-slider-lg.ui-slider-vertical {
  width: 8px;
}
.ui-slider-lg.ui-slider-vertical .ui-slider-handle {
  left: -8px;
  margin-bottom: -12px;
}
.ui-slider-sm .ui-slider-handle,
.ui-slider-xs .ui-slider-handle {
  width: 12px;
  height: 12px;
}
.ui-slider-sm .ui-slider-handle:after,
.ui-slider-xs .ui-slider-handle:after {
  width: 4px;
  height: 4px;
  margin-top: -2px;
  margin-left: -2px;
}
.ui-slider-sm.ui-slider-horizontal {
  height: 4px;
}
.ui-slider-sm.ui-slider-horizontal .ui-slider-handle {
  top: -4px;
  margin-left: -6px;
}
.ui-slider-sm.ui-slider-vertical {
  width: 4px;
}
.ui-slider-sm.ui-slider-vertical .ui-slider-handle {
  left: -4px;
  margin-bottom: -6px;
}
.ui-slider-xs.ui-slider-horizontal {
  height: 2px;
}
.ui-slider-xs.ui-slider-horizontal .ui-slider-handle {
  top: -5px;
  margin-left: -6px;
}
.ui-slider-xs.ui-slider-vertical {
  width: 2px;
}
.ui-slider-xs.ui-slider-vertical .ui-slider-handle {
  left: -5px;
  margin-bottom: -6px;
}
.ui-slider-primary .ui-slider-range,
.ui-slider-solid.ui-slider-primary .ui-slider-handle {
  background-color: #2196F3;
  border-color: #2196F3;
}
.ui-slider-danger .ui-slider-range,
.ui-slider-solid.ui-slider-danger .ui-slider-handle {
  background-color: #F44336;
  border-color: #F44336;
}
.ui-slider-success .ui-slider-range,
.ui-slider-solid.ui-slider-success .ui-slider-handle {
  background-color: #4CAF50;
  border-color: #4CAF50;
}
.ui-slider-warning .ui-slider-range,
.ui-slider-solid.ui-slider-warning .ui-slider-handle {
  background-color: #FF5722;
  border-color: #FF5722;
}
.ui-slider-info .ui-slider-range,
.ui-slider-solid.ui-slider-info .ui-slider-handle {
  background-color: #00BCD4;
  border-color: #00BCD4;
}
.ui-spinner {
  position: relative;
  display: table;
}
.ui-spinner-input {
  padding-right: 30px;
  display: table-cell;
  width: 100%;
  border-radius: 3px 0 0 3px;
  border-right: 0;
}
.ui-spinner-button {
  font-size: 0;
  color: #333333;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  display: table-cell;
  width: 1%;
  padding: 0 7px;
}
.ui-spinner-button:after {
  font-family: 'Icomoon';
  display: inline-block;
  width: 16px;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ui-spinner-button + .ui-spinner-button {
  border-left: 0;
}
.ui-spinner-button .ui-button-text {
  display: none;
}
.ui-spinner-button.ui-state-hover,
.ui-spinner-button.ui-state-focus {
  color: #333333;
}
.ui-spinner-button.ui-state-disabled {
  background-color: #fafafa;
  color: #999999;
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ui-spinner-up:after {
  content: '\e9f7';
}
.ui-spinner-down {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.ui-spinner-down:after {
  content: '\e9e2';
}
.ui-tabs {
  position: relative;
}
.ui-tabs .ui-tabs-nav {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
.ui-tabs .ui-tabs-nav li {
  list-style: none;
  position: relative;
  padding: 0;
  white-space: nowrap;
  margin-bottom: -1px;
}
@media (min-width: 769px) {
  .ui-tabs .ui-tabs-nav li {
    float: left;
  }
}
.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
  display: block;
  color: #888;
  padding: 9px 15px;
  border: 1px solid transparent;
  border-top-width: 2px;
}
.ui-tabs .ui-tabs-nav .ui-state-hover .ui-tabs-anchor:not(.ui-state-disabled),
.ui-tabs .ui-tabs-nav .ui-state-focus .ui-tabs-anchor:not(.ui-state-disabled) {
  color: #333333;
}
.ui-tabs .ui-tabs-nav .ui-tabs-active .ui-tabs-anchor {
  color: #333333;
  background-color: #fff;
  cursor: default;
  border-color: #2196F3 #ddd transparent;
}
.ui-tabs .ui-tabs-nav .ui-state-disabled {
  color: #999999;
  cursor: not-allowed;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.ui-tabs .ui-tabs-nav .ui-sortable-helper:not(.ui-tabs-active) .ui-tabs-anchor {
  background-color: #fff;
  border-bottom-color: #ddd;
}
@media (max-width: 768px) {
  .ui-tabs .ui-tabs-nav {
    border-bottom: 0;
    position: relative;
    background-color: #fff;
    padding: 7px 0;
    border: 1px solid #ddd;
    border-radius: 3px;
  }
  .ui-tabs .ui-tabs-nav li {
    margin-bottom: 0;
  }
  .ui-tabs .ui-tabs-nav li + li {
    margin-top: 1px;
  }
  .ui-tabs .ui-tabs-nav .ui-tabs-anchor {
    border-width: 0 0 0 2px;
    border-left-color: transparent;
  }
  .ui-tabs .ui-tabs-nav .ui-state-hover .ui-tabs-anchor,
  .ui-tabs .ui-tabs-nav .ui-state-focus .ui-tabs-anchor {
    background-color: #fafafa;
  }
  .ui-tabs .ui-tabs-nav .ui-state-active .ui-tabs-anchor {
    border-left-color: #2196F3;
    background-color: #f5f5f5;
  }
  .ui-tabs .ui-tabs-nav:before {
    content: 'Contents';
    color: inherit;
    font-size: 12px;
    line-height: 1.6666667;
    font-weight: 500;
    margin-top: 8px;
    margin-left: 15px;
    margin-bottom: 15px;
    text-transform: uppercase;
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
}
.ui-tabs .ui-tabs-panel {
  display: block;
}
.ui-tooltip {
  position: absolute;
  z-index: 1070;
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #333;
  border-radius: 3px;
}
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.ui-helper-hidden {
  display: none;
}
.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
}
.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}
.ui-helper-clearfix:after {
  clear: both;
}
.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
}
.ui-front {
  z-index: 1040;
}
/* ------------------------------------------------------------------------------
 *
 *  # Datatables library
 *
 *  Add advanced interaction controls to any HTML table
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.dataTable {
  margin: 0;
  max-width: none;
  border-collapse: separate;
}
.dataTable thead th,
.dataTable thead td {
  outline: 0;
  position: relative;
}
.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc,
.dataTable thead .sorting {
  cursor: pointer;
}
.dataTable thead .sorting,
.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc,
.dataTable thead .sorting_asc_disabled,
.dataTable thead .sorting_desc_disabled {
  padding-right: 40px;
}
.dataTable thead .sorting:before,
.dataTable thead .sorting:after,
.dataTable thead .sorting_asc:after,
.dataTable thead .sorting_desc:after,
.dataTable thead .sorting_asc_disabled:after,
.dataTable thead .sorting_desc_disabled:after {
  content: '';
  font-family: 'icomoon';
  position: absolute;
  top: 50%;
  right: 20px;
  font-size: 12px;
  margin-top: -6px;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dataTable thead .sorting:before {
  content: '\e9c1';
  margin-top: -2px;
  color: #999999;
}
.dataTable thead .sorting:after {
  content: '\e9c2';
  margin-top: -10px;
  color: #999999;
}
.dataTable thead .sorting_asc:after {
  content: '\e9c2';
}
.dataTable thead .sorting_desc:after {
  content: '\e9c1';
}
.dataTable thead .sorting_asc_disabled:after {
  content: '\e9c2';
  color: #ccc;
}
.dataTable thead .sorting_desc_disabled:after {
  content: '\e9c1';
  color: #ccc;
}
.dataTable tbody > tr:first-child > th,
.dataTable tbody > tr:first-child > td {
  border-top: 0;
}
.dataTable tbody + thead > tr:first-child > th,
.dataTable tbody + tfoot + thead > tr:first-child > th,
.dataTable tbody + thead > tr:first-child > td,
.dataTable tbody + tfoot + thead > tr:first-child > td {
  border-top: 0;
}
.dataTable .dataTables_empty {
  text-align: center;
}
.dataTable.table-bordered {
  border-collapse: collapse;
}
.dataTables_wrapper {
  position: relative;
  clear: both;
}
.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}
.dataTables_wrapper .table-bordered {
  border-top: 0;
}
.dataTables_wrapper + .panel-body {
  border-top: 1px solid #ddd;
}
.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  background-color: #fff;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
}
.datatable-header,
.datatable-footer {
  padding: 20px 20px 0 20px;
}
.datatable-header:after,
.datatable-footer:after {
  content: "";
  display: table;
  clear: both;
}
.datatable-header > div:first-child,
.datatable-footer > div:first-child {
  margin-left: 0;
}
.panel-body .datatable-header,
.panel-body .datatable-footer {
  padding-left: 0;
  padding-right: 0;
}
.datatable-header {
  border-bottom: 1px solid #ddd;
}
.datatable-footer {
  border-top: 1px solid #bbb;
}
.dataTables_length {
  float: right;
  display: inline-block;
  margin: 0 0 20px 20px;
}
.dataTables_length > label {
  margin-bottom: 0;
}
.dataTables_length > label > span:first-child {
  float: left;
  margin: 8px 15px;
  margin-left: 0;
}
.length-left .dataTables_length {
  float: left;
}
.dataTables_length .select2-container {
  width: auto;
}
.dataTables_length .select2-choice {
  min-width: 60px;
}
.dataTables_filter {
  position: relative;
  display: block;
  float: left;
  margin: 0 0 20px 20px;
}
.dataTables_filter > label {
  margin-bottom: 0;
  position: relative;
}
.dataTables_filter > label:after {
  content: "\e98e";
  font-family: 'icomoon';
  font-size: 12px;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -6px;
  color: #999999;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dataTables_filter > label > span {
  float: left;
  margin: 8px 15px;
  margin-left: 0;
}
.dataTables_filter input {
  outline: 0;
  width: 200px;
  height: 36px;
  padding: 7px 12px;
  padding-right: 36px;
  font-size: 13px;
  line-height: 1.5384616;
  color: #333333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
}
.filter-right .dataTables_filter {
  float: right;
}
.dataTables_info {
  float: left;
  padding: 8px 0;
  margin-bottom: 20px;
}
.info-right .dataTables_info {
  float: right;
}
.dataTables_paginate {
  float: right;
  text-align: right;
  margin: 0 0 20px 20px;
}
.dataTables_paginate .paginate_button {
  display: inline-block;
  padding: 7px;
  min-width: 36px;
  margin-left: 2px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #333333;
  border: 1px solid transparent;
  border-radius: 3px;
}
.dataTables_paginate .paginate_button:first-child {
  margin-left: 0;
}
.dataTables_paginate .paginate_button:hover,
.dataTables_paginate .paginate_button:focus {
  background-color: #f5f5f5;
}
.dataTables_paginate .paginate_button.current,
.dataTables_paginate .paginate_button.current:hover,
.dataTables_paginate .paginate_button.current:focus {
  color: #fff;
  background-color: #455A64;
}
.dataTables_paginate .paginate_button.disabled,
.dataTables_paginate .paginate_button.disabled:hover,
.dataTables_paginate .paginate_button.disabled:focus {
  cursor: default;
  background-color: transparent;
  color: #bbb;
}
.dataTables_paginate.paging_simple .paginate_button {
  padding-left: 12px;
  padding-right: 12px;
}
.paginate-left .dataTables_paginate {
  float: left;
}
.paging_simple .paginate_button:hover,
.paging_simple .paginate_button:focus {
  color: #fff;
  background-color: #455A64;
}
.dataTables_scroll {
  clear: both;
}
.dataTables_scroll .dataTables_scrollHead table {
  border-bottom: 0;
}
.dataTables_scroll .dataTables_scrollHead th,
.dataTables_scroll .dataTables_scrollHead td {
  white-space: nowrap;
}
.dataTables_scroll .dataTables_scrollBody {
  -webkit-overflow-scrolling: touch;
}
.dataTables_scroll .dataTables_scrollBody table {
  border-bottom: 0;
}
.dataTables_scroll .dataTables_scrollBody table thead th[class*=sorting]:before,
.dataTables_scroll .dataTables_scrollBody table thead th[class*=sorting]:after {
  content: none;
}
.dataTables_scroll .dataTables_scrollBody table tbody tr:first-child > td {
  border-top: 0;
}
.dataTables_scroll .dataTables_scrollBody th,
.dataTables_scroll .dataTables_scrollBody td {
  white-space: nowrap;
}
.dataTables_scroll .dataTables_scrollBody th > .dataTables_sizing,
.dataTables_scroll .dataTables_scrollBody td > .dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.panel-body + .dataTables_wrapper,
.panel-body + * > .dataTables_wrapper {
  border-top: 1px solid #ddd;
}
.panel-body > .dataTables_wrapper .datatable-footer {
  border-top: 0;
}
.panel-body > .dataTables_wrapper .datatable-footer .dataTables_length,
.panel-body > .dataTables_wrapper .datatable-footer .dataTables_filter,
.panel-body > .dataTables_wrapper .datatable-footer .dataTables_info,
.panel-body > .dataTables_wrapper .datatable-footer .dataTables_paginate {
  margin-bottom: 0;
}
.panel-flat > .panel-heading + .dataTables_wrapper > .datatable-header {
  padding-top: 0;
}
.panel > .dataTables_wrapper .table-bordered {
  border: 0;
}
.panel > .dataTables_wrapper .table-bordered > thead > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > thead > tr > th:first-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > th:first-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > th:first-child {
  border-left: 0;
}
.panel > .dataTables_wrapper .table-bordered > thead > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > thead > tr > th:last-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > th:last-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > th:last-child {
  border-right: 0;
}
.panel > .dataTables_wrapper .table-bordered > tbody > tr:last-child > th,
.panel > .dataTables_wrapper .table-bordered > tbody > tr:last-child > td {
  border-bottom: 0;
}
.datatable-scroll-lg,
.datatable-scroll,
.datatable-scroll-sm {
  min-height: .01%;
}
.datatable-scroll-wrap {
  width: 100%;
  min-height: .01%;
  overflow-x: auto;
}
@media (max-width: 768px) {
  .datatable-scroll-sm {
    width: 100%;
    overflow-x: scroll;
  }
  .datatable-scroll-sm th,
  .datatable-scroll-sm td {
    white-space: nowrap;
  }
}
@media (max-width: 1024px) {
  .datatable-scroll {
    width: 100%;
    overflow-x: scroll;
  }
  .datatable-scroll th,
  .datatable-scroll td {
    white-space: nowrap;
  }
}
@media (max-width: 1199px) {
  .datatable-scroll-lg {
    width: 100%;
    overflow-x: scroll;
  }
  .datatable-scroll-lg th,
  .datatable-scroll-lg td {
    white-space: nowrap;
  }
}
@media (max-width: 768px) {
  .dataTables_info,
  .dataTables_paginate,
  .dataTables_length,
  .dataTables_filter,
  .DTTT_container,
  .ColVis {
    float: none!important;
    text-align: center;
    margin-left: 0;
  }
  .dataTables_info,
  .dataTables_paginate {
    margin-top: 0;
  }
  .datatable-header {
    text-align: center;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Row Reorder extension
*
*  RowReorder adds the ability for rows in a DataTable to be reordered through
*  user interaction with the table.
*
*  Version: 1.0
*  Latest update: Nov 10, 2015
*
* ---------------------------------------------------------------------------- */
.dt-rowReorder-float {
  position: absolute!important;
  table-layout: static;
  outline: 1px dashed #777;
  outline-offset: -1px;
  background-color: #fff;
  z-index: 1030;
  cursor: move;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.dt-rowReorder-moving {
  outline: 1px solid #777;
  outline-offset: -1px;
}
.dt-rowReorder-noOverflow {
  overflow-x: hidden;
}
.dataTable td.reorder {
  text-align: center;
  cursor: move;
}
/* ------------------------------------------------------------------------------
*
*  # Chart styling
*
*  Charts base - container and sizing setup
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.chart-container {
  position: relative;
  width: 100%;
}
.chart-container.has-scroll {
  overflow-x: scroll;
  overflow-y: visible;
  max-width: 100%;
}
@media (max-width: 768px) {
  .chart-container {
    overflow-x: scroll;
    overflow-y: visible;
    max-width: 100%;
  }
}
.chart {
  position: relative;
  display: block;
  width: 100%;
  direction: ltr;
}
.chart.has-minimum-width {
  min-width: 600px;
}
.has-fixed-height {
  height: 400px;
}
.chart-pie {
  width: 100%;
  height: 400px;
  min-width: 500px;
}
/* ------------------------------------------------------------------------------
 *
 *  # Task manager
 *
 *  Styles related to task manager - detailed task, list and grid
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.timer-weekdays {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
.timer-weekdays > li {
  display: inline-block;
  margin: 2px 0;
}
.timer-weekdays > li > a {
  display: block;
  margin: 0 1px;
}
.timer-weekdays .label-default {
  background-color: #ccc;
  border-color: #ccc;
}
.timer-weekdays .label-default:hover,
.timer-weekdays .label-default:focus {
  border-color: #999999;
}
.timer {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}
.timer > li {
  font-size: 46px;
  text-align: center;
  display: inline-block;
  color: #555;
  font-weight: 300;
}
.timer > li.dots {
  font-size: 25px;
  vertical-align: top;
  padding: 16.66666667px 0;
}
.timer > li > span {
  display: block;
  font-size: 13px;
}
.task-details {
  float: right;
  margin: 0;
}
@media (max-width: 1024px) {
  .task-details {
    float: none;
    margin-top: 15px;
    list-style: none;
    padding: 0;
  }
  .task-details > li {
    float: left;
    position: relative;
    padding-right: 20px;
    margin-top: 0;
  }
  .task-details > li:before {
    content: '•';
    position: absolute;
    right: 8px;
    color: #999;
  }
  .task-details > li:last-child:before {
    content: none;
  }
}
@media (max-width: 768px) {
  .task-details > li {
    float: none;
  }
  .task-details > li:before {
    content: none;
  }
  .task-details > li + li {
    margin-top: 10px;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # User profile
 *
 *  Styles for all user profile layouts
 *
 *  Version: 1.1
 *  Latest update: Mar 6, 2016
 *
 * ---------------------------------------------------------------------------- */
.profile-cover {
  position: relative;
}
.profile-cover + .navbar {
  margin-bottom: 40px;
}
.profile-cover .media {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}
.profile-cover .media .btn-default {
  border-color: #fcfcfc;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
.profile-cover .media-body,
.profile-cover small {
  color: #fff;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
}
@media (max-width: 768px) {
  .profile-cover .media {
    text-align: center;
    margin-top: 0;
    bottom: auto;
    top: 40px;
  }
  .profile-cover .media-left,
  .profile-cover .media-right {
    padding: 0;
    display: block;
    overflow-x: auto;
  }
  .profile-cover .media-body {
    display: block;
    margin-bottom: 20px;
    width: 100%;
  }
  .profile-cover .media-body > h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}
.profile-cover-img {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
@media (max-width: 768px) {
  .profile-cover-img {
    height: 320px;
  }
}
.media .profile-thumb img {
  width: 100px;
  height: 100px;
  border: 3px solid #fff;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
/* ------------------------------------------------------------------------------
 *
 *  # Login and related forms
 *
 *  Styles related to user login - logins, registration, password revovery, unlock etc.
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.login-cover {
  background: url('../images/login_cover.jpg');
  /*background-size: cover;*/
}
.login-container .page-container {
  padding-top: 40px;
  position: static;
}
.login-container .page-container .login-form {
  width: 320px;
}
.login-container .page-container .login-form .thumb {
  margin: 0 auto 20px auto;
}
.login-container .page-container .login-form,
.login-container .page-container .registration-form {
  margin: 0 auto 20px auto;
}
@media (max-width: 480px) {
  .login-container .page-container .login-form,
  .login-container .page-container .registration-form {
    width: 100%;
  }
}
.login-container .page-container .nav-tabs.nav-justified {
  margin-bottom: 0;
}
.login-container .page-container .nav-tabs.nav-justified > li > a {
  border-top: 0!important;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #f5f5f5;
}
.login-container .page-container .nav-tabs.nav-justified > li:first-child > a {
  border-left: 0;
  border-radius: 3px 0 0 0;
}
.login-container .page-container .nav-tabs.nav-justified > li:last-child > a {
  border-right: 0;
  border-radius: 0 3px 0 0;
}
.login-container .page-container .nav-tabs.nav-justified > li.active > a {
  background-color: transparent;
}
@media (max-width: 768px) {
  .login-container .page-container .nav-tabs.nav-justified {
    padding: 0;
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .login-container .page-container .nav-tabs.nav-justified:before {
    content: none;
  }
  .login-container .page-container .nav-tabs.nav-justified > li > a {
    border-width: 0!important;
  }
}
.login-container .footer {
  left: 0;
  right: 0;
}
@media (max-width: 768px) {
  .login-options,
  .login-options .text-right {
    text-align: center;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Timeline
 *
 *  Styles for timeline in 3 layouts: left, right and centered
 *
 *  Version: 1.1
 *  Latest update: Dec 11, 2015
 *
 * ---------------------------------------------------------------------------- */
.timeline {
  position: relative;
  height: 100%;
}
.timeline:before,
.timeline:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2px;
  background-color: #ccc;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  z-index: 1;
}
.timeline:after {
  top: auto;
  bottom: 0;
}
.timeline-container {
  position: relative;
  padding-top: 10px;
  margin-top: -10px;
  padding-bottom: 1px;
}
.timeline-container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1px;
  background-color: #ccc;
  height: 100%;
  width: 2px;
  z-index: -1;
}
.timeline-row {
  position: relative;
}
.timeline-content {
  margin-bottom: 20px;
}
.timeline-date {
  text-align: center;
  background-color: #f5f5f5;
  position: relative;
  z-index: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.timeline-date:before,
.timeline-date:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2px;
  background-color: #ccc;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  z-index: 1;
}
.timeline-date:after {
  top: auto;
  bottom: 0;
}
.panel .timeline-date {
  background-color: #fff;
}
.timeline-time {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f5f5f5;
  position: relative;
  margin-bottom: 20px;
}
.timeline-time:before,
.timeline-time:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2px;
  background-color: #ccc;
  height: 4px;
  width: 4px;
  border-radius: 50%;
}
.timeline-time:after {
  top: auto;
  bottom: 0;
}
.panel .timeline-time {
  background-color: #fff;
}
.timeline-time span {
  display: block;
  color: #999999;
  font-size: 12px;
}
.timeline-time span:before {
  content: '•';
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: 1px;
}
@media (min-width: 1025px) {
  .timeline-time:before,
  .timeline-time:after {
    content: none;
  }
}
.timeline-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin: 0 auto 20px auto;
  background-color: #fff;
  border: 4px solid #f5f5f5;
}
.panel .timeline-icon {
  border-color: #fff;
}
.timeline-icon div {
  border-radius: 50%;
  height: 100%;
  -webkit-box-shadow: 0 0 0 2px #ccc inset;
  box-shadow: 0 0 0 2px #ccc inset;
}
.timeline-icon div[class*=bg-] {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.timeline-icon div > i {
  display: block;
  line-height: 40px;
  top: 0;
}
.timeline-icon img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
@media (min-width: 1025px) {
  .timeline-center .timeline-row {
    margin-right: 50%;
    padding-right: 44px;
  }
  .timeline-center .post-even {
    margin-right: 0;
    padding-right: 0;
    margin-left: 50%;
    padding-left: 44px;
  }
  .timeline-center .post-full {
    margin: 0;
    padding: 0;
  }
  .timeline-center .post-full .timeline-content {
    position: relative;
  }
  .timeline-center .post-full .timeline-content:before,
  .timeline-center .post-full .timeline-content:after {
    content: '';
    border-style: solid;
    border-color: #f5f5f5;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
  }
  .timeline-center .post-full .timeline-content:before {
    border-width: 4px 0 0 0;
    top: -5px;
  }
  .timeline-center .post-full .timeline-content:after {
    border-width: 0 0 4px 0;
    bottom: -5px;
  }
  .timeline-left .timeline-container:after,
  .timeline-left:before,
  .timeline-left:after,
  .timeline-left .timeline-date:before,
  .timeline-left .timeline-date:after {
    left: 24px;
  }
  .timeline-left .timeline-row,
  .timeline-left .timeline-date {
    padding-left: 68px;
  }
  .timeline-right .timeline-container:after,
  .timeline-right:before,
  .timeline-right:after,
  .timeline-right .timeline-date:before,
  .timeline-right .timeline-date:after {
    left: auto;
    right: 24px;
    margin-left: 0;
    margin-right: -2px;
  }
  .timeline-right .timeline-container:after {
    margin-right: -1px;
  }
  .timeline-right .timeline-row,
  .timeline-right .timeline-date {
    padding-right: 68px;
  }
  .timeline-left .timeline-date,
  .timeline-right .timeline-date {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .timeline-icon {
    position: absolute;
    top: 10px;
  }
  .timeline-icon:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    height: 2px;
    width: 16px;
    background-color: #ccc;
    z-index: 1;
  }
  .timeline-left .timeline-icon {
    left: 0;
  }
  .timeline-left .timeline-icon:after {
    left: 100%;
    margin-left: 4px;
  }
  .timeline-right .timeline-icon {
    right: 0;
  }
  .timeline-right .timeline-icon:after {
    right: 100%;
    margin-right: 4px;
  }
  .timeline-center .timeline-row:not(.post-full) .timeline-icon {
    left: 100%;
    margin-left: -24px;
  }
  .timeline-center .timeline-row.post-even .timeline-icon {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: -24px;
  }
  .timeline-center .timeline-icon:after {
    right: 100%;
    margin-right: 4px;
  }
  .timeline-center .post-even .timeline-icon:after {
    left: 100%;
    margin-left: 4px;
  }
  .timeline-center .post-full .timeline-icon {
    position: static;
  }
  .timeline-center .post-full .timeline-icon:after {
    content: none;
  }
  .timeline-time {
    padding: 0;
    text-align: inherit;
    background-color: transparent;
  }
  .timeline-time:before {
    content: none;
  }
  .timeline-left .timeline-time,
  .timeline-right .timeline-time {
    padding-top: 10px;
    margin-bottom: 20px;
    padding-left: 10px;
  }
  .timeline-right .timeline-time {
    text-align: right;
    padding-left: 0;
    padding-right: 10px;
  }
  .timeline-center .timeline-time,
  .timeline-center .post-full .timeline-time {
    position: absolute;
    left: 100%;
    top: 15px;
    width: 100%;
    padding-left: 44px;
  }
  .timeline-center .post-even .timeline-time,
  .timeline-center .post-full .timeline-time {
    left: auto;
    right: 100%;
    padding-left: 0;
    padding-right: 44px;
    text-align: right;
  }
  .timeline-center .post-full .timeline-time {
    right: 50%;
    top: 5px;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Chat layouts
 *
 *  Conversation chat styles - layouts, chat elements, colors, options
 *
 *  Version: 1.1
 *  Latest update: Oct 20, 2015
 *
 * ---------------------------------------------------------------------------- */
.chat-list,
.chat-stacked {
  max-height: 520px;
  overflow: auto;
}
.chat-list .media-left {
  padding-right: 20px;
}
.chat-list .media-right {
  padding-left: 20px;
}
.chat-list .media-content {
  position: relative;
  padding: 10px 12px;
  width: auto;
  border-radius: 3px;
  display: inline-block;
}
.chat-list .media-content:not([class*=bg-]) {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.chat-list .media-content > i {
  top: 0;
  margin: 2px;
}
.chat-list .reversed .media-content {
  text-align: left;
  color: #fff;
}
.chat-list .reversed .media-content:not([class*=bg-]) {
  background-color: #42A5F5;
  border-color: #42A5F5;
}
.chat-list .reversed .media-content > a {
  color: #fff;
}
.chat-list .reversed .media-body {
  text-align: right;
}
.chat-list .date-step.media {
  text-align: center;
  margin-left: 0;
  margin-right: 0;
  color: #999;
}
.chat-list .chat-thumbs img {
  margin-top: 5px;
  margin-bottom: 5px;
  max-height: 100px;
  height: auto;
  max-width: 100%;
  display: inline-block;
  border-radius: 3px;
}
.chat-list .media-content:before,
.chat-list .reversed .media-content:before {
  content: '';
  left: -5px;
  top: 15px;
  position: absolute;
  margin-left: 0;
}
.chat-list .media-content:before {
  border-top: 5px solid transparent;
  border-right: 5px solid;
  border-right-color: inherit;
  border-bottom: 5px solid transparent;
}
.chat-list .reversed .media-content:before {
  left: auto;
  right: -5px;
  border-right: 0;
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-left-color: inherit;
  border-bottom: 5px solid transparent;
}
@media (min-width: 769px) {
  .chat-list .media {
    margin-right: 20%;
  }
  .chat-list .media.reversed {
    margin-right: 0;
    margin-left: 20%;
  }
}
.chat-list-inverse .media-content:not([class*=bg-]) {
  color: #fff;
  background-color: #42A5F5;
  border-color: #42A5F5;
}
.chat-list-inverse .media-content:before {
  border-right: 0;
  border-top: 5px solid transparent;
  border-right: 5px solid #42A5F5;
  border-bottom: 5px solid transparent;
}
.chat-list-inverse .media-content > a {
  color: #fff;
}
.chat-list-inverse .reversed .media-content:not([class*=bg-]) {
  background-color: #f5f5f5;
  color: inherit;
}
.chat-list-inverse .reversed .media-content:before {
  border-top: 5px solid transparent;
  border-left: 5px solid #f5f5f5;
  border-bottom: 5px solid transparent;
}
/* ------------------------------------------------------------------------------
 *
 *  # Search
 *
 *  Styles for search results and input
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.panel .main-search input[type=text] {
  background-color: #FAFAFA;
}
.search-results-list .list-inline {
  margin-bottom: 5px;
}
.related-searches {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}
.website-search-results {
  padding: 20px;
  padding-bottom: 0;
  border-bottom: 1px solid #eeeeee;
}
.media-preview {
  width: auto;
  height: auto;
  max-width: none;
  max-height: 90px;
}
@media (max-width: 768px) {
  .search-option-buttons {
    text-align: center;
  }
  .search-option-buttons > .text-right {
    text-align: inherit;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Error pages
 *
 *  Styles for error and offline pages
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.error-title {
  color: #fff;
  font-size: 200px;
  line-height: 1;
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: 300;
  text-stroke: 1px transparent;
  display: block;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15);
}
@media (max-width: 768px) {
  .error-title {
    font-size: 130px;
  }
}
.offline-title {
  font-size: 160px;
}
@media (max-width: 768px) {
  .offline-title {
    font-size: 90px;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Heading elmeents
*
*  Display default and custom components in page header and panel heading
*
*  Version: 1.3
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */
.heading-elements-toggle {
  cursor: pointer;
  display: block;
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -8px;
}
.breadcrumb-elements-toggle {
  float: right;
  cursor: pointer;
  line-height: 1;
  margin-top: 12px;
}
.breadcrumb-line-component .breadcrumb-elements-toggle {
  margin-right: 20px;
}
.heading-elements-toggle,
.breadcrumb-elements-toggle,
.heading-elements-toggle:hover,
.breadcrumb-elements-toggle:hover,
.heading-elements-toggle:focus,
.breadcrumb-elements-toggle:focus {
  color: inherit;
}
.heading-elements-toggle > i,
.breadcrumb-elements-toggle > i {
  top: 0;
}
@media (min-width: 769px) {
  .heading-elements-toggle,
  .breadcrumb-elements-toggle {
    display: none;
  }
}
.heading-elements {
  background-color: inherit;
  position: absolute;
  top: 50%;
  right: 20px;
  height: 36px;
  margin-top: -18px;
}
@media (max-width: 768px) {
  .heading-elements:not(.not-collapsible) {
    position: static;
    margin-top: 0;
    height: auto;
  }
}
.panel-body > .heading-elements {
  top: 0;
  margin-top: 14px;
  z-index: 10;
}
@media (max-width: 768px) {
  .panel-body > .heading-elements-toggle {
    top: 20px;
    margin-top: 0;
  }
  .panel-body > .heading-elements.visible-elements {
    top: 56px;
    background-color: #fff;
  }
}
.panel-footer > .heading-elements {
  position: static;
  margin-top: 0;
  padding-right: 20px;
}
.panel-footer > .heading-elements:after {
  content: '';
  display: table;
  clear: both;
}
.panel-footer-bordered > .heading-elements:not(.visible-elements) {
  margin-left: -20px;
  padding-right: 0;
}
@media (max-width: 768px) {
  .panel-footer > .heading-elements:not(.not-collapsible) > .pull-right {
    float: none!important;
  }
  .panel-footer .heading-elements-toggle {
    position: static;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 0;
    display: block;
    text-align: center;
  }
}
.heading-thumbnails {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
}
.heading-thumbnails > li {
  position: relative;
  display: inline-block;
  font-size: 13px;
}
.heading-thumbnails > li + li {
  margin-left: 10px;
}
.heading-thumbnails > li img {
  height: auto;
  max-height: 36px;
  max-width: 100%;
  border-radius: 100px;
}
.heading-thumbnails > li .label,
.heading-thumbnails > li .badge {
  position: absolute;
  top: -8px;
  right: -8px;
  border: 2px solid #fcfcfc;
}
.panel-default > .panel-heading .heading-thumbnails > li .label,
.panel-default > .panel-heading .heading-thumbnails > li .badge,
.panel-footer-transparent .heading-thumbnails > li .label,
.panel-footer-transparent .heading-thumbnails > li .badge {
  border-color: #fff;
}
.heading-thumbnails > li .status-mark {
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 0 0 0 2px #fcfcfc;
}
.heading-thumbnails > li .status-mark[class*=border-] {
  background-color: #fcfcfc;
}
.panel-default > .panel-heading .heading-thumbnails > li .status-mark[class*=border-],
.panel-footer-transparent .heading-thumbnails > li .status-mark[class*=border-] {
  background-color: #fff;
}
.panel-default > .panel-heading .heading-thumbnails > li .status-mark,
.panel-footer-transparent .heading-thumbnails > li .status-mark {
  background-color: #fff;
}
.heading-elements .heading-btn + .heading-btn,
.page-header .heading-elements .icons-list > li + li,
.thumbnail-heading .heading-elements .icons-list > li + li {
  margin-left: 10px;
}
.page-header-inverse .heading-elements .btn-link {
  color: #fff;
}
.heading-elements .list-inline {
  margin-bottom: 0;
}
.heading-elements .breadcrumb {
  padding-top: 0;
  padding-bottom: 0;
}
.heading-elements.panel-nav .nav > li > a {
  text-align: left;
}
.heading-elements .daterange-custom {
  margin-top: 4px;
}
.heading-elements .heading-btn-group {
  font-size: 0;
}
.heading-elements .heading-btn-group > .btn + .btn:not(.btn-link) {
  margin-left: 10px;
}
.heading-elements.visible-elements {
  text-align: center;
}
.heading-elements.visible-elements .heading-text,
.heading-elements.visible-elements .heading-btn,
.heading-elements.visible-elements .heading-btn-group > .btn,
.heading-elements.visible-elements .ui-slider,
.heading-elements.visible-elements .noui-slider,
.heading-elements.visible-elements .nav-tabs,
.heading-elements.visible-elements .nav-pills,
.heading-elements.visible-elements .pagination,
.heading-elements.visible-elements .progress,
.heading-elements.visible-elements .icons-list,
.heading-elements.visible-elements .pager,
.heading-elements.visible-elements .breadcrumb,
.heading-elements.visible-elements .daterange-custom,
.heading-elements.visible-elements .heading-thumbnails,
.heading-elements.visible-elements .heading-form .form-group,
.heading-elements.visible-elements > .btn-group {
  margin-top: 20px;
}
.heading-elements.visible-elements .nav-tabs,
.heading-elements.visible-elements .nav-pills {
  margin-bottom: 0;
}
.heading-elements.visible-elements .heading-text:not(.label):not(.badge) {
  display: block;
}
.heading-elements.visible-elements .select2-container,
.heading-elements.visible-elements .selectboxit-container,
.heading-elements.visible-elements .selectboxit-options,
.heading-elements.visible-elements .multiselect + .btn-group,
.heading-elements.visible-elements div.bootstrap-select {
  width: 100%!important;
}
.heading-elements.visible-elements .input-group,
.heading-elements.visible-elements .input-group-btn,
.heading-elements.visible-elements .btn-group,
.heading-elements.visible-elements .dropdown,
.heading-elements.visible-elements .dropup {
  position: static;
}
.heading-elements.visible-elements .dropdown-menu:not(.multiselect-container) {
  left: -1px;
  right: -1px;
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.heading-elements.visible-elements .multiselect-container {
  left: 0;
  right: 0;
}
.heading-elements:not(.visible-elements) .heading-text,
.heading-elements:not(.visible-elements) .heading-btn,
.heading-elements:not(.visible-elements) .ui-slider,
.heading-elements:not(.visible-elements) .noui-slider,
.heading-elements:not(.visible-elements) .nav-tabs,
.heading-elements:not(.visible-elements) .nav-pills,
.heading-elements:not(.visible-elements) .pagination,
.heading-elements:not(.visible-elements) .progress,
.heading-elements:not(.visible-elements) .icons-list,
.heading-elements:not(.visible-elements) .breadcrumb,
.heading-elements:not(.visible-elements) .pager,
.heading-elements:not(.visible-elements) .heading-form,
.heading-elements:not(.visible-elements) .daterange-custom,
.heading-elements:not(.visible-elements) .heading-thumbnails,
.heading-elements:not(.visible-elements) > .btn-group {
  float: left;
  margin-left: 20px;
}
.heading-elements:not(.visible-elements) .heading-text {
  display: inline-block;
}
.heading-elements:not(.visible-elements) .heading-text + .heading-text {
  margin-left: 20px;
}
.heading-elements:not(.visible-elements) .selectbox-fixed + .selectboxit-container,
.heading-elements:not(.visible-elements) .selectbox-fixed + .selectboxit-options,
.heading-elements:not(.visible-elements) .progress,
.heading-elements:not(.visible-elements) .ui-slider,
.heading-elements:not(.visible-elements) .noui-slider,
.heading-elements:not(.visible-elements) .input-group,
.heading-elements:not(.visible-elements) .selectboxit-container .selectboxit-options,
.heading-elements:not(.visible-elements) .uploader,
.heading-elements:not(.visible-elements) .heading-form .form-control {
  width: 220px;
}
.heading-elements:not(.visible-elements) .select-sm,
.heading-elements:not(.visible-elements) .input-sm,
.heading-elements:not(.visible-elements) .input-group-sm,
.heading-elements:not(.visible-elements) .uploader-sm,
.heading-elements:not(.visible-elements) .pagination-sm,
.heading-elements:not(.visible-elements) .pager-sm,
.heading-elements:not(.visible-elements) .selectbox-sm + .selectboxit-container,
.heading-elements:not(.visible-elements) .btn-sm,
.heading-elements:not(.visible-elements) .btn-group-sm > .btn {
  margin-top: 1px;
}
.heading-elements:not(.visible-elements) .select-xs,
.heading-elements:not(.visible-elements) .input-xs,
.heading-elements:not(.visible-elements) .input-group-xs,
.heading-elements:not(.visible-elements) .uploader-xs,
.heading-elements:not(.visible-elements) .pagination-xs,
.heading-elements:not(.visible-elements) .pager-xs,
.heading-elements:not(.visible-elements) .selectbox-xs + .selectboxit-container,
.heading-elements:not(.visible-elements) .btn-xs,
.heading-elements:not(.visible-elements) .btn-group-xs > .btn {
  margin-top: 2px;
}
.heading-elements:not(.visible-elements) .btn-float {
  margin-top: -8px;
}
.heading-elements:not(.visible-elements) .btn-float.has-text {
  margin-top: -19.5px;
}
.heading-elements:not(.visible-elements) .btn-float.btn-link {
  margin-top: -14px;
}
.heading-elements:not(.visible-elements) .ui-slider,
.heading-elements:not(.visible-elements) .noui-slider {
  margin-top: 15px;
}
.heading-elements:not(.visible-elements) .ui-slider-lg,
.heading-elements:not(.visible-elements) .noui-slider-lg {
  margin-top: 14px;
}
.heading-elements:not(.visible-elements) .ui-slider-sm,
.heading-elements:not(.visible-elements) .noui-slider-sm {
  margin-top: 16px;
}
.heading-elements:not(.visible-elements) .ui-slider-xs,
.heading-elements:not(.visible-elements) .noui-slider-xs {
  margin-top: 17px;
}
.heading-elements:not(.visible-elements) .progress {
  margin-top: 9px;
}
.heading-elements:not(.visible-elements) .progress-lg {
  margin-top: 7px;
}
.heading-elements:not(.visible-elements) .progress-sm {
  margin-top: 11px;
}
.heading-elements:not(.visible-elements) .progress-xs {
  margin-top: 13px;
}
.heading-elements:not(.visible-elements) .progress-xxs {
  margin-top: 15px;
}
.heading-elements:not(.visible-elements) .progress-micro {
  margin-top: 17px;
}
.heading-elements:not(.visible-elements) .icons-list {
  margin-top: 10px;
}
.heading-elements:not(.visible-elements) .heading-text {
  margin-top: 8px;
}
.heading-elements:not(.visible-elements).panel-nav > .nav {
  margin-bottom: 0;
}
@media (min-width: 769px) {
  .heading-elements:not(.visible-elements).panel-nav > .nav {
    margin-top: -1px;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav-tabs {
    border-bottom-width: 0;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav-tabs.nav-tabs-bottom > li.active > a:after {
    background-color: transparent;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav-sm {
    margin-top: 2px;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav-xs {
    margin-top: 5px;
  }
}
@media (max-width: 768px) {
  .heading-elements:not(.visible-elements).panel-nav {
    position: static;
    height: auto;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav {
    float: none;
  }
  .panel-heading .heading-elements:not(.visible-elements).panel-nav {
    margin-top: 15px;
  }
  .panel-flat .panel-heading .heading-elements:not(.visible-elements).panel-nav {
    margin-top: 20px;
  }
  .panel-heading .heading-elements:not(.visible-elements).panel-nav > .nav {
    margin-left: 0;
  }
  .panel-footer .heading-elements:not(.visible-elements).panel-nav.not-collapsible > .nav {
    margin-top: 13px;
    margin-bottom: 13px;
  }
}
.heading-form .form-group {
  margin-bottom: 0;
}
.heading-form .checkbox-switchery,
.heading-form .checkbox-switchery[class*="switchery-"] {
  margin-bottom: 0;
}
.heading-elements:not(.visible-elements) .heading-form .checkbox-inline,
.heading-elements:not(.visible-elements) .heading-form .radio-inline {
  margin-top: 8px;
}
.heading-elements:not(.visible-elements) .heading-form .checkbox-switch {
  margin-top: 2px;
}
.heading-elements:not(.visible-elements) .heading-form .checkbox-switch-sm {
  margin-top: 3px;
}
.heading-elements:not(.visible-elements) .heading-form .checkbox-switch-xs {
  margin-top: 4px;
}
.heading-elements:not(.visible-elements) .heading-form .input-group {
  max-width: 220px;
}
.heading-elements:not(.visible-elements) .heading-form .input-group .form-control {
  width: 100%;
  margin-top: 0;
}
.heading-elements:not(.visible-elements) .heading-form .input-group.input-group-sm .btn,
.heading-elements:not(.visible-elements) .heading-form .input-group.input-group-xs .btn {
  margin-top: 0;
}
@media (min-width: 769px) {
  .heading-form .form-group {
    float: left;
  }
  .heading-form .form-group + .form-group {
    margin-left: 15px;
  }
}
@media (max-width: 768px) {
  .heading-elements:not(.not-collapsible),
  .breadcrumb-elements:not(.not-collapsible) {
    display: none;
  }
  .heading-elements.visible-elements,
  .breadcrumb-elements.visible-elements {
    display: block;
  }
  .panel .has-visible-elements {
    padding-bottom: 0;
  }
  .panel-flat .panel-heading.has-visible-elements {
    padding-bottom: 20px;
  }
  .panel .visible-elements {
    border-top: 1px solid #ddd;
    padding: 20px;
    padding-top: 0;
  }
  .panel-heading .visible-elements {
    margin: 15px -20px 0 -20px;
    background-color: #fcfcfc;
  }
  .panel-flat .panel-heading .visible-elements {
    margin-top: 20px;
    border-bottom: 1px solid #ddd;
  }
  .panel-footer .visible-elements {
    margin-top: 7px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .panel-footer-condensed .visible-elements {
    margin-top: 2px;
  }
  .panel-footer-transparent .visible-elements {
    margin-top: 12px;
    background-color: #fff;
  }
  .panel[class*=bg-] .visible-elements,
  .panel-heading[class*=bg-] .visible-elements,
  .panel-primary .visible-elements,
  .panel-danger .visible-elements,
  .panel-success .visible-elements,
  .panel-warning .visible-elements,
  .panel-info .visible-elements {
    border-color: rgba(255, 255, 255, 0.2);
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .visible-elements.breadcrumb-elements.not-collapsible {
    display: none;
  }
  .page-header-content > .visible-elements {
    background-color: #f5f5f5;
    left: 0;
    right: 0;
    border-top: 1px solid #dcdcdc;
    z-index: 1002;
    padding-bottom: 20px;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .page-header-default .page-header-content > .visible-elements {
    background-color: #fcfcfc;
    border-color: #ddd;
  }
  .page-header-inverse .page-header-content > .visible-elements {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
  }
}
/* ------------------------------------------------------------------------------
*
*  # Helper classes
*
*  Custom helper classes
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.no-edge-top {
  top: 0;
}
.no-edge-bottom {
  bottom: 0;
}
.no-edge-left {
  left: 0;
}
.no-edge-right {
  right: 0;
}
@media (min-width: 1200px) {
  .pull-right-lg {
    float: right;
  }
}
@media (min-width: 1025px) {
  .pull-right-md {
    float: right;
  }
}
@media (min-width: 769px) {
  .pull-right-sm {
    float: right;
  }
}
@media (min-width: 480px) {
  .pull-right-xs {
    float: right;
  }
}
.valign-top {
  vertical-align: top;
}
.valign-middle {
  vertical-align: middle;
}
.valign-bottom {
  vertical-align: bottom;
}
.valign-baseline {
  vertical-align: baseline;
}
.valign-text-top {
  vertical-align: top;
}
.valign-text-bottom {
  vertical-align: text-bottom;
}
.position-relative {
  position: relative;
}
.position-static {
  position: static;
}
.display-block,
label.display-block {
  display: block;
}
.display-inline-block {
  display: inline-block;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}
.cursor-default {
  cursor: default;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-visible {
  overflow: visible;
}
.overflow-auto {
  overflow: auto;
}
.content-group-xs {
  margin-bottom: 10px !important;
}
.content-group-sm {
  margin-bottom: 15px !important;
}
.content-group {
  margin-bottom: 20px !important;
}
.content-group-lg {
  margin-bottom: 30px !important;
}
.no-margin {
  margin: 0!important;
}
.no-margin-top {
  margin-top: 0!important;
}
.no-margin-bottom {
  margin-bottom: 0!important;
}
.no-margin-left {
  margin-left: 0!important;
}
.no-margin-right {
  margin-right: 0!important;
}
.m-5 {
  margin: 5px!important;
}
.m-10 {
  margin: 10px!important;
}
.m-15 {
  margin: 15px!important;
}
.m-20 {
  margin: 20px!important;
}
.mt-5 {
  margin-top: 5px!important;
}
.mt-10 {
  margin-top: 10px!important;
}
.mt-15 {
  margin-top: 15px!important;
}
.mt-20 {
  margin-top: 20px!important;
}
.mb-5 {
  margin-bottom: 5px!important;
}
.mb-10 {
  margin-bottom: 10px!important;
}
.mb-15 {
  margin-bottom: 15px!important;
}
.mb-20 {
  margin-bottom: 20px!important;
}
.ml-5 {
  margin-left: 5px!important;
}
.ml-10 {
  margin-left: 10px!important;
}
.ml-15 {
  margin-left: 15px!important;
}
.ml-20 {
  margin-left: 20px!important;
}
.mr-5 {
  margin-right: 5px!important;
}
.mr-10 {
  margin-right: 10px!important;
}
.mr-15 {
  margin-right: 15px!important;
}
.mr-20 {
  margin-right: 20px!important;
}
.no-padding {
  padding: 0!important;
}
.no-padding-top {
  padding-top: 0!important;
}
.no-padding-bottom {
  padding-bottom: 0!important;
}
.no-padding-left {
  padding-left: 0!important;
}
.no-padding-right {
  padding-right: 0!important;
}
.p-5 {
  padding: 5px!important;
}
.p-10 {
  padding: 10px!important;
}
.p-15 {
  padding: 15px!important;
}
.p-20 {
  padding: 20px!important;
}
.pt-5 {
  padding-top: 5px!important;
}
.pt-10 {
  padding-top: 10px!important;
}
.pt-15 {
  padding-top: 15px!important;
}
.pt-20 {
  padding-top: 20px!important;
}
.pb-5 {
  padding-bottom: 5px!important;
}
.pb-10 {
  padding-bottom: 10px!important;
}
.pb-15 {
  padding-bottom: 15px!important;
}
.pb-20 {
  padding-bottom: 20px!important;
}
.pl-5 {
  padding-left: 5px!important;
}
.pl-10 {
  padding-left: 10px!important;
}
.pl-15 {
  padding-left: 15px!important;
}
.pl-20 {
  padding-left: 20px!important;
}
.pr-5 {
  padding-right: 5px!important;
}
.pr-10 {
  padding-right: 10px!important;
}
.pr-15 {
  padding-right: 15px!important;
}
.pr-20 {
  padding-right: 20px!important;
}
.no-border {
  border: 0!important;
}
.no-border-top {
  border-top: 0!important;
}
.no-border-bottom {
  border-bottom: 0!important;
}
.no-border-left {
  border-left: 0!important;
}
.no-border-right {
  border-right: 0!important;
}
.no-border-radius {
  border-radius: 0;
}
.no-border-radius-top {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.no-border-radius-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.no-border-radius-left {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.no-border-radius-right {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.border-top {
  border-top: 1px solid;
}
.border-bottom {
  border-bottom: 1px solid;
}
.border-left {
  border-left: 1px solid;
}
.border-right {
  border-right: 1px solid;
}
.border-lg {
  border-width: 2px;
}
.border-top-lg {
  border-top: 2px solid;
}
.border-bottom-lg {
  border-bottom: 2px solid;
}
.border-left-lg {
  border-left: 2px solid;
}
.border-right-lg {
  border-right: 2px solid;
}
.border-xlg {
  border-width: 3px;
}
.border-top-xlg {
  border-top: 3px solid;
}
.border-bottom-xlg {
  border-bottom: 3px solid;
}
.border-left-xlg {
  border-left: 3px solid;
}
.border-right-xlg {
  border-right: 3px solid;
}
.full-width {
  width: 100%;
}
@media (min-width: 769px) {
  .width-200 {
    min-width: 200px;
  }
  .width-250 {
    min-width: 250px;
  }
  .width-300 {
    min-width: 300px;
  }
  .width-350 {
    min-width: 350px;
  }
  .width-400 {
    min-width: 400px;
  }
  .width-450 {
    min-width: 450px;
  }
  .width-500 {
    min-width: 500px;
  }
  .width-550 {
    min-width: 550px;
  }
  .width-600 {
    min-width: 600px;
  }
  .width-650 {
    min-width: 650px;
  }
}
.img-lg {
  width: 44px!important;
  height: 44px!important;
}
.img-sm {
  width: 36px!important;
  height: 36px!important;
}
.img-xs {
  width: 32px!important;
  height: 32px!important;
}
